import React from "react";
import "./location360.css";
import StackedBarChart from '../../components/charts/StackedBarChart';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { scannedChartData, tilesData, locationFilter, deviceFilter, exportData, getMsgData, sendMsgData } from '../../middlewares/Location360Api';
import DownloadCsvFile from '../../components/DownloadCsvFile';
import moment from 'moment';
import { object } from "prop-types";
import * as Toasts from '../../components/Toasts';
import { Modal, Button } from "react-bootstrap";
import { localstorage } from '../../config/axiosConfig';


class Location360 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startdate: new Date(),
      enddate: new Date(),
      choosedate: '',
      customdate: false,
      devices: 'all',
      location: 'all',
      sacnnedChartData: [],
      locationList: [],
      deviceList: [],
      salarystaf: 0,
      hourlystaf: 0,
      exportData: [],
      tableData: [],
      tableLocation: [],
      tableDevice: [],
      tableType: [],
      tableAccess: [],
      droplocation: 'all',
      dropdevice: 'all',
      droptype: 'all',
      dropaccess: 'all',
      tableVisiterState: [],
      dropVisiterState: 'all',
      showModal: false,
      msg: '',
      msgList: [],
      visitorkey: '',
      rowvalue: []
    }
  }

  componentDidMount() {
    // console.log('headerpath', this.props);
    this.getLocationFilterData();
    this.getDeviceList();
    this.getTilesData();
    this.getScannedChartData();
    this.getExportData();
  }

  getScannedChartData = async () => {
    let { devices, location, enddate, startdate } = this.state;
    startdate.setHours(0, 0, 0, 0);
    enddate.setHours(23, 59, 59, 0);
    // console.log('zone', new Date().getTimezoneOffset());
    // console.log('current', startdate);
    // console.log('momnet', moment(startdate).format());
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    let json = {};
    if (startdate || enddate) {
      json.startdate = moment(startdate).utc().format();
      json.enddate = moment(enddate).utc().format();
    }
    devices = devices === 'all' ? '' : devices;
    if (devices) {
      json.device = devices;
    }
    location = location === 'all' ? '' : location;
    if (location) {
      json.location = location;
    }
    const sacnnedChartDataprevious = [...this.state.sacnnedChartData];
    // this.setState({
    //   sacnnedChartData: []
    // });
    try {
      const data = await scannedChartData(Object.freeze(json));
      // console.log(data);
      if (data.status === 200) {
        const chartData = data.data;
        let formatData = [];
        // console.log(data.data);
        if (chartData && chartData.length) {
          const unique = [...new Set(chartData.map(item => item.visitorname))];
          unique.map(itemdata => {
            const filterData = chartData.filter(item => item.visitorname === itemdata);
            let finalFilter = {
              "category": itemdata,
              "Normal": 0,
              "AbnormalQuestionnaire": 0,
              "AccessFail": 0,
              "AbnormalTemperature": 0
            };
            filterData.map(item => {
              if (item.visitstatename === "Abnormal Temperature") {
                finalFilter.AbnormalTemperature = +item.count;
              } else if (item.visitstatename === 'Normal') {
                finalFilter.Normal = +item.count;
              } else if (item.visitstatename === 'Access Fail') {
                finalFilter.AccessFail = +item.count;
              } else if (item.visitstatename === 'Abnormal Questionnaire') {
                finalFilter.AbnormalQuestionnaire = +item.count;
              }
            });
            formatData.push(finalFilter);
          });
        }
        this.setState({
          sacnnedChartData: formatData.length ? formatData : []
        });
        // console.log('state-value', this.state.sacnnedChartData);
      }
    } catch (error) {
      this.setState({
        scannedChartData: sacnnedChartDataprevious
      })
    }

  }

  getTilesData = async () => {
    let { devices, location, enddate, startdate } = this.state;

    startdate.setHours(0, 0, 0, 0);
    enddate.setHours(23, 59, 59, 0);

    let json = {};
    if (startdate || enddate) {
      json.startdate = moment(startdate).utc().format();
      json.enddate = moment(enddate).utc().format();
    }
    devices = devices === 'all' ? '' : devices;
    if (devices) {
      json.device = devices;
    }
    location = location === 'all' ? '' : location;
    if (location) {
      json.location = location;
    }
    this.setState({ hourlystaf: 0 });
    this.setState({ salarystaf: 0 });
    try {
      const data = await tilesData(Object.freeze(json));

      if (data.status === 200) {
        // console.log(data.data);
        if (data.data.length) {
          data.data.map(item => {
            if (item.visitorname === 'Hourly Staff') {
              this.setState({ hourlystaf: item.count });
            } else if (item.visitorname === 'Salary Staff') {
              this.setState({ salarystaf: item.count });
            }
          })
        }
      }
    } catch (error) {

    }
  }

  getLocationFilterData = async () => {
    try {
      const data = await locationFilter();

      if (data.status === 200) {
        let LocationList = [];
        if (data.data.length) {
          LocationList = data.data.map(value => {
            return {
              id: value.location,
              locationname: value.location
            };
          });
          LocationList.unshift({ id: 'all', locationname: 'All' });
        }
        this.setState({
          locationList: LocationList,
        });
        // console.log(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  getDeviceList = async () => {
    try {
      const data = await deviceFilter();
      if (data.status === 200) {
        let deviceList = [];
        if (data.data.length) {
          deviceList = data.data.map(value => {
            return {
              id: value.devicename,
              deviceName: value.devicename
            };
          });
          deviceList.unshift({ id: 'all', deviceName: 'All' });
        }
        this.setState({
          deviceList: deviceList,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }


  fromDateChange = date => {
    // console.log(date);
    this.setState({
      startdate: date
    });
    setTimeout(() => {
      this.getTilesData();
      this.getScannedChartData();
      this.getExportData();
    }, 500)
  };

  toDataChange = date => {
    this.setState({
      enddate: date
    });
    setTimeout(() => {
      this.getTilesData();
      this.getScannedChartData();
      this.getExportData();
    }, 500)
  }

  changeLocation = (e) => {
    // console.log(e.target.value);
    this.setState({
      location: e.target.value
    });
    setTimeout(() => {
      this.getTilesData();
      this.getScannedChartData();
      this.getExportData();
    }, 500)
  }

  chageDevice = (e) => {
    // console.log(e.target.value);
    this.setState({
      devices: e.target.value
    });
    setTimeout(() => {
      this.getTilesData();
      this.getScannedChartData();
      this.getExportData();
    }, 500)
  }
  chooseDateDropDown = e => {
    // console.log(e.target.value);
    this.setState({
      choosedate: e.target.value
    })

    if (e.target.value === 'customdate') {
      this.setState({
        customdate: true
      });
    } else {
      this.setState({
        customdate: false,
        startdate: new Date(),
        enddate: new Date()
      });
      setTimeout(() => {
        this.getScannedChartData();
      }, 500)
    }

  }

  getExportData = async () => {
    this.setState({
      droplocation: 'all',
      dropdevice: 'all',
      droptype: 'all',
      dropaccess: 'all',
      dropVisiterState: 'all'
    });
    let { devices, location, enddate, startdate } = this.state;
    startdate.setHours(0, 0, 0, 0);
    enddate.setHours(23, 59, 59, 0);
    // const newdate = new Date(startdate).setHours(0, 0, 0, 0);
    // console.log('start->',);
    // console.log('momnet=>', moment(startdate).utc().format());
    // console.log('end momnet=>', moment(enddate).utc().format());
    let json = {};
    if (startdate || enddate) {
      json.startdate = moment(startdate).utc().format();
      json.enddate = moment(enddate).utc().format();
    }
    devices = devices === 'all' ? '' : devices;
    if (devices) {
      json.device = devices;
    }
    location = location === 'all' ? '' : location;
    if (location) {
      json.location = location;
    }
    try {
      const data = await exportData(Object.freeze(json));
      // console.log('export==>', data);
      if (data.status === 200 && data.data.length > 0) {
        const Device = [...new Set(data.data.map(item => item.devicename))];
        const Location = [...new Set(data.data.map(item => item.locationname))];
        const Type = [...new Set(data.data.map(item => item.visitortype))];
        const Access = [...new Set(data.data.map(item => item.access))];
        const VisiterSate = [...new Set(data.data.map(item => item.visitstatename))];
        data.data.sort(function (a, b) {
          return new Date(b.createtimewithtime) - new Date(a.createtimewithtime);
        });
        const tableVisiterState = VisiterSate.map(value => {
          return {
            id: value,
            visiterstatename: value
          };
        });
        tableVisiterState.unshift({ id: 'all', visiterstatename: 'Visitor State' });
        const tableDevice = Device.map(value => {
          return {
            id: value,
            devicename: value
          };
        });
        tableDevice.unshift({ id: 'all', devicename: 'Device' });
        const tableLocation = Location.map(value => {
          return {
            id: value,
            locationname: value
          };
        });
        tableLocation.unshift({ id: 'all', locationname: 'Location' });
        const tableType = Type.map(value => {
          return {
            id: value,
            typename: value
          };
        });
        tableType.unshift({ id: 'all', typename: 'Type' });
        const tableAccess = Access.map(value => {
          return {
            id: value,
            accessname: value
          };
        });
        tableAccess.unshift({ id: 'all', accessname: 'Access' });

        this.setState({
          exportData: data.data,
          tableData: data.data,
          tableDevice: tableDevice,
          tableLocation: tableLocation,
          tableType: tableType,
          tableAccess: tableAccess,
          tableVisiterState: tableVisiterState
        })
      } else {
        this.setState({
          exportData: [],
          tableData: [],
          tableDevice: [],
          tableLocation: [],
          tableType: [],
          tableAccess: [],
          tableVisiterState: []
        })
      }
    } catch (error) {

    }
  }

  getCsvFile = async () => {
    const { exportData } = this.state;
    if (exportData.length > 0) {
      const keys = Object.keys(exportData[0]);
      const noteaqual = ["visitrecordkey", "visitorkey", "remarks"];
      let headers = {}
      keys.map(item => {
        // console.log(item);
        // console.log(noteaqual[item]);
        if (!noteaqual.includes(item)) {
          headers[item] = item.replace(/,/g, '')
        }
      });
      const itemsNotFormatted = exportData;

      const itemsFormatted = [];
      itemsNotFormatted.forEach((item) => {
        itemsFormatted.push({
          visitstatename: item.visitstatename ? item.visitstatename.replace(/,/g, '') : '',
          access: item.access ? item.access.replace(/,/g, '') : '',
          clockno: item.clockno ? item.clockno.replace(/,/g, '') : '',
          createtimewithtime: item.createtimewithtime ? this.formatDate(item.createtimewithtime.replace(/,/g, '')) : '',
          devicename: item.devicename ? item.devicename.replace(/,/g, '') : '',
          locationname: item.locationname ? item.locationname.replace(/,/g, '') : '',
          name: item.name ? item.name.replace(/,/g, '') : '',
          temperature: item.temperature ? item.temperature.replace(/,/g, '') : '',
          visitortype: item.visitortype ? item.visitortype.replace(/,/g, '') : ''
        });
      });

      var fileTitle = 'location'; // or 'my-unique-title'
      await DownloadCsvFile(headers, itemsFormatted, fileTitle);
    } else {
      Toasts.Info('Record Not Found!');
    }
  }

  exportTable = async () => {
    const { tableData } = this.state;

    if (tableData.length > 0) {
      const keys = Object.keys(tableData[0]);
      const noteaqual = ["visitrecordkey", "visitorkey"];
      let headers = {}
      keys.map(item => {
        if (!noteaqual.includes(item)) {
          headers[item] = item.replace(/,/g, '');
        }
      });
      const itemsNotFormatted = tableData;
      const itemsFormatted = [];
      itemsNotFormatted.forEach((item) => {
        const remark = item.remarks.map(remark => {
          return remark.username + ' ' + this.formatDate(remark.createdon) + ' ' + remark.remark;
        });
        // console.log('---------', remark);
        itemsFormatted.push({
          visitstatename: item.visitstatename ? item.visitstatename.replace(/,/g, '') : '',
          access: item.access ? item.access.replace(/,/g, '') : '',
          clockno: item.clockno ? item.clockno.replace(/,/g, '') : '',
          createtimewithtime: item.createtimewithtime ? this.formatDate(item.createtimewithtime.replace(/,/g, '')) : '',
          devicename: item.devicename ? item.devicename.replace(/,/g, '') : '',
          locationname: item.locationname ? item.locationname.replace(/,/g, '') : '',
          name: item.name ? item.name.replace(/,/g, '') : '',
          temperature: item.temperature ? item.temperature.replace(/,/g, '') : '',
          visitortype: item.visitortype ? item.visitortype.replace(/,/g, '') : '',
          remarks: remark.length > 0 ? Object.keys(remark).map(k => remark[k]).join() : ''
        });
      });

      var fileTitle = 'locationTable'; // or 'my-unique-title'
      await DownloadCsvFile(headers, itemsFormatted, fileTitle);
    } else {
      Toasts.Info('Record Not Found!');
    }

  }

  filterTableValue = (e, type) => {
    // console.log('value=', e.target.value);
    // console.log('type', type);
    if (e.target.value === 'all') {
      return;
    } else {
      if (type === 'location') {
        this.setState({
          droplocation: e.target.value,
        });
      } else if (type === 'device') {
        this.setState({
          dropdevice: e.target.value,
        });
      } else if (type === 'type') {
        this.setState({
          droptype: e.target.value,
        });
      } else if (type === 'access') {
        this.setState({
          dropaccess: e.target.value,
        });
      } else if (type === 'visiterstate') {
        this.setState({
          dropVisiterState: e.target.value
        });
        // filterdata = exportData.filter(item => item.visitstatename === e.target.value);
      }
      setTimeout(() => {
        this.setFilterData();
      }, 500);
    }

  }

  setFilterData = () => {
    const { exportData, droplocation, dropdevice, droptype, dropaccess, dropVisiterState } = this.state;
    let Obj = {};

    if (droplocation && droplocation !== 'all') {
      Obj.locationname = droplocation;
    }

    if (dropdevice && dropdevice !== 'all') {
      Obj.devicename = dropdevice;
    }

    if (droptype && droptype !== 'all') {
      Obj.visitortype = droptype;
    }

    if (dropaccess && dropaccess !== 'all') {
      Obj.access = dropaccess;
    }

    if (dropVisiterState && dropVisiterState !== 'all') {
      Obj.visitstatename = dropVisiterState;
    }

    // console.log('Obj=>', Obj);
    let Data = exportData;
    for (const [key, value] of Object.entries(Obj)) {
      console.log(Data);
      Data = Data.filter((item) => {
        if (item[key] === value) {
          return item;
        }
      });
    }
    // console.log('filter=>', Data);

    this.setState({
      tableData: Data
    });
  }

  reSetTable = () => {
    const { exportData } = this.state;
    this.setState({
      droplocation: 'all',
      dropdevice: 'all',
      droptype: 'all',
      dropaccess: 'all',
      dropVisiterState: 'all'
    });
    this.setState({
      tableData: exportData
    });
  }

  getChartData = (data) => {
    console.log('location', data);
  }

  formatDate = (date) => {
    const fomratDate = new Date(date);
    return moment(fomratDate).format('YYYY-MM-DD H:mm:ss');
  }

  close = () => {
    this.setState({ showModal: false, msg: '', msgList: [], visitorkey: '', rowvalue: [] });
  }

  open = async (value) => {
    // console.log('=======', value)
    if (value.visitrecordkey) {
      this.setState({ visitorkey: value.visitrecordkey })
      const data = await getMsgData(value.visitrecordkey);
      console.log(data);
      if (data.status === 200) {
        this.setState({
          msgList: data.data,
          showModal: true,
          rowvalue: value
        });
      }
    }
  }

  getMsg = (e) => {
    // console.log(e.target.value);
    this.setState({ msg: e.target.value });
  }

  sendMsg = async () => {
    const { msg, visitorkey } = this.state;
    const userDetail = localstorage();
    if (msg && visitorkey, userDetail) {
      // console.log(msg);
      const senddata = {
        visitrecordkey: visitorkey,
        remark: msg,
        username: userDetail.username
      }
      const data = await sendMsgData(senddata);
      // console.log(data);
      if (data.status === 200) {
        this.setState({ msg: '' });
        Toasts.Success('Record updated successfully');
        const data2 = await getMsgData(visitorkey);
        this.getExportData();
        if (data2.status === 200) {
          this.setState({
            msgList: data2.data,
          });
        }
      }
    }
  }

  render() {
    const { locationList, deviceList, hourlystaf, salarystaf, tableData,
      tableDevice, tableLocation, tableAccess, tableType, tableVisiterState, msgList, rowvalue } = this.state;
    return (
      <React.Fragment>
        <div className="main-contnet">
          <div>
            <div>
              <div>
                <div className="content">
                  <div className="title">
                    <h5 style={{ fontWeight: "600" }}>Location 360</h5>
                  </div>
                  <hr className="hrline" />
                  <div className="filter">
                    <div className="btn-group">
                      <div style={{ paddingLeft: "33px" }}>
                        <label style={{ fontSize: "12px" }}>From Date</label>
                        <br />
                        <DatePicker
                          selected={this.state.startdate}
                          onChange={this.fromDateChange}
                        />
                      </div>
                      <div style={{ paddingLeft: "12px" }}>
                        <label style={{ fontSize: "12px" }}>To Date</label>
                        <br />
                        <DatePicker
                          selected={this.state.enddate}
                          onChange={this.toDataChange}
                        />
                      </div>
                      <div style={{ paddingLeft: "12px" }}>
                        <label style={{ fontSize: "12px" }}>Location</label>
                        <br />
                        <select style={{ marginLeft: '0px' }}
                          // id='location'
                          // name='location'
                          value={this.state.location}
                          onChange={(e) => this.changeLocation(e)}
                        >
                          {/* <option value="all" disabled selected>All</option> */}
                          {locationList &&
                            locationList.length &&
                            locationList.map((value, index) => {
                              return (
                                <option key={index} value={value.id}>
                                  {value.locationname}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div>
                        <label style={{ fontSize: "12px" }}>Devices</label>
                        <br />
                        <select style={{ marginLeft: '0px' }}
                          value={this.state.devices}
                          onChange={(e) => this.chageDevice(e)}>
                          {deviceList &&
                            deviceList.length &&
                            deviceList.map((value, index) => {
                              return (
                                <option key={index} value={value.id}>
                                  {value.deviceName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <span className='export-btn'>
                      {/*  onClick={this.DownloadCsVFile} */}
                      <button type='button' onClick={this.getCsvFile}>Export</button>
                    </span>
                  </div>
                </div>
                <div className='row' style={{ padding: "0 30px" }}>
                  {/* <div className='col'>
                    <div className='tiles'>
                      <div className="cardtitle">Location Health <span>98%</span></div>
                    </div>
                  </div> */}
                  <div className='col'>
                    <div className='tiles'>
                      <div className="cardtitle">Salary Staff <span>{salarystaf}</span></div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='tiles'>
                      <div className="cardtitle">Hourly Staff <span>{hourlystaf}</span></div>
                    </div>
                  </div>
                </div>
                <div className="passesAlign">
                  <div className="row" >
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <span className='hedingtitle'>Scanned</span>
                      </div>
                      <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                      <div className="passesAppliedMain">
                        <p style={{ color: "white" }}>{this.state.sacnnedChartData.length > 0 ?
                          <StackedBarChart data={this.state.sacnnedChartData} chartid={'location'}
                            chartclick={false} chartonClick={this.getChartData} /> : <div style={{ marginLeft: '407px', color: '#fff', marginTop: '68px', marginBottom: '40px' }}>No Data Found</div>}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="passesAlign">
                  <div className="row" >
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <span className='hedingtitle'>Scanned Records {'(' + tableData.length + ')'}</span>
                        <div className="d-flex align-items-center">
                          <div className='buttonfilter' style={{ backgroundColor: '#ed3125' }} onClick={this.exportTable}>Export</div>
                          <div className='buttonfilter' onClick={this.reSetTable}>Reset</div>
                        </div>
                      </div>
                      <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                      {tableData.length > 0 ?
                        <div className="adminMainTable">
                          <table >
                            <thead>
                              <tr>
                                <th>Clock No.</th>
                                <th>Name</th>
                                <th>Date & Time</th>
                                <th><select className='tabledropdaown'
                                  value={this.state.droplocation}
                                  onChange={(e) => this.filterTableValue(e, 'location')}
                                >
                                  {tableLocation &&
                                    tableLocation.length &&
                                    tableLocation.map((value, index) => {
                                      return (
                                        <option key={index} value={value.id}>
                                          {value.locationname}
                                        </option>
                                      );
                                    })}
                                </select></th>
                                {/* style={{ marginLeft: '10px', paddingLeft: '10px' }} */}
                                <th><select className='tabledropdaown'
                                  value={this.state.dropdevice}
                                  onChange={(e) => this.filterTableValue(e, 'device')}
                                >
                                  {tableDevice &&
                                    tableDevice.length &&
                                    tableDevice.map((value, index) => {
                                      return (
                                        <option key={index} value={value.id}>
                                          {value.devicename}
                                        </option>
                                      );
                                    })}
                                </select></th>
                                <th><select className='tabledropdaown'
                                  value={this.state.droptype}
                                  onChange={(e) => this.filterTableValue(e, 'type')}
                                >
                                  {tableType &&
                                    tableType.length &&
                                    tableType.map((value, index) => {
                                      return (
                                        <option key={index} value={value.id}>
                                          {value.typename}
                                        </option>
                                      );
                                    })}
                                </select></th>
                                <th><select className='tabledropdaown'
                                  value={this.state.dropaccess}
                                  onChange={(e) => this.filterTableValue(e, 'access')}
                                >
                                  {tableAccess &&
                                    tableAccess.length &&
                                    tableAccess.map((value, index) => {
                                      return (
                                        <option key={index} value={value.id}>
                                          {value.accessname}
                                        </option>
                                      );
                                    })}
                                </select></th>
                                <th><select className='tabledropdaown'
                                  value={this.state.dropVisiterState}
                                  onChange={(e) => this.filterTableValue(e, 'visiterstate')}
                                >
                                  {tableVisiterState &&
                                    tableVisiterState.length &&
                                    tableVisiterState.map((value, index) => {
                                      return (
                                        <option key={index} value={value.id}>
                                          {value.visiterstatename}
                                        </option>
                                      );
                                    })}
                                </select></th>
                                <th style={{ width: '100px' }}>Temp</th>
                                <th style={{ width: '100px' }}>Remark</th>
                              </tr>
                            </thead>
                            <tbody id='style-7'>
                              {tableData.map((value, index) => {
                                return (
                                  <React.Fragment>
                                    <tr key={index}>
                                      <td>{value.clockno}</td>
                                      <td>{value.name}</td>
                                      {/* <td>{value.createtimewithtime}</td> */}
                                      <td>{this.formatDate(value.createtimewithtime)}</td>
                                      <td>{value.locationname}</td>
                                      <td>{value.devicename}</td>
                                      <td>{value.visitortype}</td>
                                      <td>{value.access}</td>
                                      <td>{value.visitstatename}</td>
                                      <td style={{ width: '100px' }}>{value.temperature}</td>
                                      <td style={{ width: '100px' }}>
                                        <img src={'images/ksr/Chat_Icon.svg'} alt='message' onClick={() => this.open(value)} style={{ cursor: 'pointer' }} />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                        : <div style={{ marginLeft: '436px', color: '#fff', marginTop: '68px', marginBottom: '40px' }}>No Data Found</div>}
                    </div>
                  </div>
                </div>
                <Modal className="my-modal" show={this.state.showModal} onHide={this.close}>
                  <Modal.Header closeButton>
                    <Modal.Title>Remark</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='msgboxTitle'><p style={{ float: 'right', zIndex: '999' }}>{rowvalue.clockno ? rowvalue.clockno : ''}</p>
                      <p style={{ float: 'left', zIndex: '999' }}>{rowvalue.name ? rowvalue.name : 'Stranger'}</p></div>
                    <div className='scrollMsg' style={{ 'max-height': '250px', 'overflow-y': 'auto', 'width': '100%' }}>
                      {msgList.map((val, index) => {
                        return (
                          <React.Fragment>

                            <div className='msg' key={index}>
                              <p style={{ float: 'right', zIndex: '999', fontSize: '13px', marginBottom: 0 }}>{this.formatDate(val.createdon)}</p>
                              <p style={{ float: 'left', zIndex: '999', fontWeight: 'bold', marginBottom: 0 }}>{val.firstname} {val.lastname}</p>
                              <span style={{ display: 'block', textAlign: 'left', clear: 'both' }}>{val.remark}</span>

                            </div>
                          </React.Fragment>
                        )
                      })
                      }
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {/* <Button onClick={this.close}>Close</Button> */}
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" className='msginput'
                        placeholder="Type your message..." aria-label="ype your message" aria-describedby="button-addon2"
                        value={this.state.msg}
                        onChange={(e) => this.getMsg(e)}
                      />
                      <div class="input-group-append">
                        <img src={'images/ksr/send_button.svg'} alt='send' className='msginputimg' onClick={this.sendMsg} />
                      </div>
                    </div>
                  </Modal.Footer>
                  <div className='clearfix'></div>
                </Modal>
                {/* <div className="row" style={{ padding: "0 30px", marginBottom: "25px" }}>
                  <div className="col-6">
                    <div className="dailypasses">
                      <div className="d-flex justify-content-between">
                        <span className='hedingtitle'>DAILY PASSES</span>
                        <div className="d-flex align-items-center">
                          <div className='buttonfilter' style={{ backgroundColor: "#ED3125" }}>issued vs denied</div>
                          <div className='buttonfilter'>Forecast</div>
                          <div className='buttonfilter'>i</div>
                        </div>
                      </div>
                      <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                      <div className="dailypassesmain">
                        <p style={{ color: "white" }}>Chart will be heere</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="dailyentries">
                      <div className="d-flex justify-content-between">
                        <span className='hedingtitle'>DAILY ENTRIES</span>
                        <div className="d-flex align-items-center">
                          <div className='buttonfilter' style={{ backgroundColor: "#ED3125" }}>Approved vs denied</div>
                          <div className='buttonfilter'>Forecast</div>
                          <div className='buttonfilter'>i</div>
                        </div>
                      </div>
                      <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                      <div className="dailyentriesmain">
                        <p style={{ color: "white" }}>Chart will be heere</p>
                      </div>
                    </div>
                  </div>
                </div> */}

              </div>

            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default Location360;
