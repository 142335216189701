import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

// import am4themes_material from "@amcharts/amcharts4/themes/material";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_material);
// am4core.useTheme(am4themes_animated);

// import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_kelly);
// am4core.useTheme(am4themes_animated);


export default class StackedBarChart extends Component {


    componentDidMount() {
        const resultset = this.props.data;
        // console.log('chart compnent');
        this.drawChart(resultset);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('prevProps', prevProps);
        // console.log('prevState', prevState);
        const resultset = this.props.data;
        // console.log('chart compnent update', resultset);
        if (prevProps.data !== resultset) {
            // console.log('inside');
            this.drawChart(resultset);
        }
    }


    drawChart = (data) => {
        // console.log(this.props)
        let chart = am4core.create(this.props.chartid, am4charts.XYChart);
        chart.data = data;

        // chart.data = [{
        //     "category": "Stranger",
        //     "Normal": 42,
        //     "Abnormal": 1,
        // }, {
        //     "category": "Visitor",
        //     "Normal": 30,
        //     "Abnormal": 3,
        // }, {
        //     "category": "Hourly Staff",
        //     "Normal": 79,
        //     "Abnormal": 1,
        // }, {
        //     "category": "Salary Staff",
        //     "Normal": 89,
        //     "Abnormal": 2,
        // }]
        // Beavercreek Cincinnati Lorain Louisville Marengo
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.opacity = 0;
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.disabled = true; //disables axis line
        valueAxis.renderer.labels.template.disabled = true; //disables labels
        valueAxis.renderer.grid.template.disabled = true;  //disables grid
        // valueAxis.min = 0;
        // valueAxis.renderer.grid.template.opacity = 0;
        // valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
        // valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        // valueAxis.renderer.ticks.template.length = 10;
        // valueAxis.renderer.line.strokeOpacity = 0.5;
        // valueAxis.renderer.baseGrid.disabled = true;
        // valueAxis.renderer.minGridDistance = 40;
        // valueAxis.renderer.labels.template.fill = am4core.color("#fff");
        // valueAxis.renderer.labels.template.fontSize = 20;


        this.createSeries("Normal", "Normal", chart);
        this.createSeries("AbnormalTemperature", "Abnormal Temperature", chart);
        this.createSeries("AbnormalQuestionnaire", "Abnormal Questionnaire", chart);
        this.createSeries("AccessFail", "Access Fail", chart);
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.contentAlign = "right";
        chart.legend.labels.template.fill = am4core.color("#fff");
        chart.legend.valueLabels.template.fill = am4core.color("#fff");
        chart.legend.labels.template.truncate = false;
        chart.legend.labels.template.wrap = true;
    }

    createSeries = (field, name, chart) => {
        let series = chart.series.push(new am4charts.ColumnSeries());
        if (this.props.chartclick) {
            series.columns.template.events.on("hit", function (ev) {
                console.log("clicked on ", ev.target.dataItem.dataContext);
                this.props.chartonClick(ev.target.dataItem.dataContext);
            }, this);
        }
        series.columns.template.width = am4core.percent(1);
        if (name === 'Normal') {
            series.fill = am4core.color("#12AF07");
            series.stroke = am4core.color("#12AF07");
        } else if (name === 'Abnormal Temperature') {
            series.fill = am4core.color("#d91e18");
            series.stroke = am4core.color("#d91e18");
        } else if (name === 'Abnormal Questionnaire') {
            series.fill = am4core.color("#d14d21");
            series.stroke = am4core.color("#d14d21");
        } else if (name === 'Access Fail') {
            series.fill = am4core.color("#d17f21");
            series.stroke = am4core.color("#d17f21");
        }
        series.dataFields.valueX = field;
        series.dataFields.categoryY = "category";
        series.stacked = true;
        series.name = name;

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.locationX = 0.5;
        labelBullet.label.text = "{valueX}";
        labelBullet.label.fill = am4core.color("#000");
    }
    render() {
        return (
            <div id={`${this.props.chartid}`} style={{ width: "100%", height: "400px" }}></div>
        )
    }
}
