import http, { headers } from '../config/axiosConfig';

export const scannedChartData = async (data) => {
    const request = await http.get('location360/data', {
        params: data,
        headers: headers()
    });
    return request;
};

export const tilesData = async (data) => {
    const request = await http.get('location360/salary', {
        params: data,
        headers: headers()
    });
    return request;
};

export const locationFilter = async () => {
    const request = await http.get('location360/location', {
        headers: headers()
    });
    return request;
};

export const deviceFilter = async () => {
    const request = await http.get('location360/devicename', {
        headers: headers()
    });
    return request;
};

export const exportData = async (data) => {
    const request = await http.get('/location360/export', {
        params: data,
        headers: headers()
    });
    return request;
};

export const getMsgData = async (id) => {
    const request = await http.get(`/remarks/${id}`, {
        headers: headers()
    });
    return request;
}

export const sendMsgData = async (data) => {
    const request = await http.post('/remarks', data, { headers: headers() });
    return request;
}

