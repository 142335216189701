import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormMiddleware from './../../middlewares/FormMiddleware';
import {
  saveOrg,
  updateOrg,
  getParticularOrg,
} from '../../middlewares/RbacMiddleware';

class ManageOrganization extends FormMiddleware {
  constructor(props) {
    super(props);
    this.state = {
      address1: '',
      address2: '',
      city: '',
      companyname: '',
      companytype: '',
      contactnumber: '',
      country: '',
      createdby: '',
      createddate: '',
      isadmin: false,
      state: '',
      status: '',
      website: '',
      zipcode: '',
      orgId: '',
      updatedby: '',
    };
  }

  async componentDidMount() {
    const orgId = this.props.match.params.id;
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.setState({
      createdby: userDetails.displayname,
      updatedby: userDetails.displayname,
    });
    if (!orgId) {
      this.singleSelectBox('status');
    } else {
      this.setState({
        orgId,
      });
      this.getParticularOrg(orgId);
    }
  }

  getParticularOrg = async (orgId) => {
    try {
      const { data } = await getParticularOrg(orgId);
      if (!data) return;
      console.log(data);
      const country = document.querySelector(`#country`);
      country.value = data.country;
      const state = document.querySelector(`#state`);
      state.value = data.state;
      const companytype = document.querySelector(`#companytype`);
      companytype.value = data.companytype;
      const status = document.querySelector(`#status`);
      if (data.status === 'A') {
        status.checked = true;
      }
      this.setState({
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        country: data.country,
        companyname: data.companyname,
        companytype: data.companytype,
        contactnumber: data.contactnumber,
        state: data.state,
        status: data.status,
        website: data.website,
        zipcode: data.zipcode,
        createdby: data.createdby,
        isadmin: data.isadmin,
        createddate: data.createddate,
        updateddate: data.updateddate,
      });
    } catch (error) {
      console.error(error);
    }
  };
  singleSelectBox = (value) => {
    const getHtml = document.querySelector(`#${value}`);
    if (getHtml.checked) {
      getHtml.checked = true;
      this.setState({
        [value]: 'A',
      });
    } else {
      getHtml.checked = false;
      this.setState({
        [value]: 'I',
      });
    }
  };

  onSave = async () => {
    const {
      address1,
      address2,
      city,
      country,
      companyname,
      companytype,
      contactnumber,
      state,
      status,
      website,
      zipcode,
      createdby,
      isadmin,
    } = this.state;
    if (!address1) return;
    if (!city) return;
    if (!country) return;
    if (!companyname) return;
    if (!companytype) return;
    if (!contactnumber) return;
    if (!state) return;
    if (!zipcode) return;
    const json = {
      address1,
      address2,
      city,
      companyname,
      companytype,
      contactnumber,
      country,
      createdby,
      createddate: new Date(),
      isadmin,
      state,
      status,
      website,
      zipcode,
    };
    try {
      const { data } = await saveOrg(json);
      if (!data) return;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  onUpdate = async () => {
    const {
      address1,
      address2,
      city,
      country,
      companyname,
      companytype,
      contactnumber,
      state,
      status,
      website,
      zipcode,
      createdby,
      isadmin,
      orgId,
      createddate,
      updatedby,
    } = this.state;
    if (!address1) return;
    if (!city) return;
    if (!country) return;
    if (!companyname) return;
    if (!companytype) return;
    if (!contactnumber) return;
    if (!state) return;
    if (!zipcode) return;
    const json = {
      address1,
      address2,
      city,
      companyname,
      companytype,
      contactnumber,
      country,
      createdby,
      createddate,
      isadmin,
      state,
      status,
      website,
      zipcode,
      updatedby,
      updateddate: new Date(),
    };
    try {
      const { data } = await updateOrg(json, orgId);
      if (!data) return;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  onDelete = async () => {
    const {
      address1,
      address2,
      city,
      country,
      companyname,
      companytype,
      contactnumber,
      state,
      status,
      website,
      zipcode,
      createdby,
      isadmin,
      orgId,
      createddate,
      updatedby,
    } = this.state;
    if (!address1) return;
    if (!city) return;
    if (!country) return;
    if (!companyname) return;
    if (!companytype) return;
    if (!contactnumber) return;
    if (!state) return;
    if (!zipcode) return;
    const json = {
      address1,
      address2,
      city,
      companyname,
      companytype,
      contactnumber,
      country,
      createdby,
      createddate,
      isadmin,
      state,
      status: 'D',
      website,
      zipcode,
      updatedby,
      updateddate: new Date(),
    };
    try {
      const { data } = await updateOrg(json, orgId);
      if (!data) return;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const {
      address1,
      address2,
      city,
      companyname,
      companytype,
      contactnumber,
      state,
      status,
      website,
      zipcode,
      orgId,
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper">
          <div id="content-wrapper">
            <div className="tp-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item" style={{ cursor: 'pointer' }}>
                  <img src={'images/fileicon.png'} alt="" />
                </li>
                <li className="breadcrumb-item activelink">
                  <Link to="/admindashboard">Admin</Link>
                </li>
                <li className="breadcrumb-item active">ADD NEW ORGANIZATION</li>
              </ol>
            </div>
            <div className="container-fluid mb-15" id="project-list"></div>
            <div className="white-box" style={{ overflow: 'hidden' }}>
              <div className="col-md-12">
                <form autoComplete="off">
                  <div className="row">
                    <div className="col-md-6 backtoorganisationlist">
                      <Link to="/manageorganizationList">
                        {' '}
                        <i className="fas fa-reply"></i>
                        <span>Back to List</span>
                      </Link>
                    </div>
                  </div>
                  <div className="admin_listpage">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <label className="filter-lbl col-md-4">
                              Company Logo
                            </label>
                            <div className="col-md-7">
                              <label className="newbtn">
                                <img
                                  src={'images/addcompany.png'}
                                  alt="Add Company"
                                />
                                <br />
                                Drag logo here to upload(Max.size 4MB)
                                <br />
                                (or)
                                <br />
                                <span>Choose file</span>
                                <button
                                  id="pictureuploadd"
                                  type="file"
                                  accept="image/*"
                                ></button>
                              </label>
                              <label className="removebtnimg">x Remove</label>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Company Name<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Company Name"
                                name="companyname"
                                id="companyname"
                                value={companyname}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Company Type<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                name="companytype"
                                id="companytype"
                                onChange={(e) => this.handleUserInput(e)}
                              >
                                <option value="">Select Type</option>
                                <option value="RETAIL">Retail</option>
                                <option value="REAL_ESTATE">Real Estate</option>
                                <option value="HEALTHCARE">HEALTHCARE</option>
                              </select>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Phone Number<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <PhoneInput
                                country={'us'}
                                className="form-control"
                                placeholder="Phone Number"
                                value={contactnumber}
                                onChange={(phone) =>
                                  this.setState({ contactnumber: phone })
                                }
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Website
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Website Link"
                                id="website"
                                name="website"
                                value={website}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Status
                            </label>
                            <div
                              className="col-md-7"
                              style={{ paddingTop: '15px' }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="status"
                                name="status"
                                onChange={(e) => this.singleSelectBox('status')}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="status"
                              ></label>
                              <span>Active</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="filter-lbl admindetails_headings offset-md-1  col-md-4">
                              Location Details
                            </label>
                            <div className="col-md-7"></div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Address 1<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 1"
                                name="address1"
                                id="address1"
                                value={address1}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Address 2<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 2"
                                name="address2"
                                id="address2"
                                value={address2}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              City<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                                id="city"
                                value={city}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              State<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                id="state"
                                name="state"
                                onChange={(e) => this.handleUserInput(e)}
                              >
                                <option value="">Select State</option>
                                <option value="Michigan">Michigan</option>
                                <option value="Massachusetts">
                                  Massachusetts
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Country<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                name="country"
                                id="country"
                                onChange={(e) => this.handleUserInput(e)}
                              >
                                <option value="">Select Country</option>
                                <option value="USA">USA</option>
                              </select>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Zip Code<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Zip Code"
                                name="zipcode"
                                id="zipcode"
                                value={zipcode}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pull-right admin_actionsbtn">
                    <button type="button" className="btn btn-default">
                      Cancel
                    </button>
                    {!orgId ? (
                      <button
                        type="button"
                        className="btn btn-default btn-save"
                        onClick={(e) => this.onSave()}
                      >
                        Save
                      </button>
                    ) : (
                      <React.Fragment>
                        <button
                          type="button"
                          className="btn btn-default btn-save"
                          onClick={(e) => this.onUpdate()}
                        >
                          Update & Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-default btn-save"
                          onClick={(e) => this.onDelete()}
                        >
                          Delete
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ManageOrganization;
