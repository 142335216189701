import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class PieChart extends Component {
    componentDidMount() {
        const resultset = this.props.resultSet;
        this.drewChart(resultset);
    }

    drewChart = (data) => {
        const catagory = this.props.catagory;
        const pie1name = this.props.pie1ChartName;
        const pie2name = this.props.pie2ChartName;
        let chart = am4core.create(this.props.chartid, am4charts.PieChart);
        chart.data = data;
        const pieSeries = chart.series.push(new am4charts.PieSeries());

        pieSeries.dataFields.value = pie1name;
        pieSeries.dataFields.category = catagory;
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.hiddenState.properties.radius = am4core.percent(0);
        chart.legend = new am4charts.Legend();
    }
    render() {
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper" className="customervalue" style={{ width: 600 }}>
                        <label><b>{this.props.title}</b></label>
                        <div id={`${this.props.chartid}`} style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PieChart;