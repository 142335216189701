import React from 'react';
import FormMiddleware from './../middlewares/FormMiddleware';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendUsername } from '../middlewares/User';
import { forgotPassword } from '../middlewares/User';
import styled from 'styled-components';

const RecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTHA;

class ForgotPassword extends FormMiddleware {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            verificationcode: '',
            password: '',
            confpassword: '',
            submituser: true,
            passwordValidate: false,
            confirmPasswordValidate: false,
            recaptchaValidate: false,
            usernameerror: '',
            recaptchaerror: '',
            verificationcodeerror: '',
            passwordConfirmationError: ''
        };
    }

    usernameSubmit = async (e) => {
        e.preventDefault();
        const { username } = this.state;
        let isValid = true;
        isValid = this.validateField('username') && isValid;
        // && this.state.recaptchaValidate
        if (isValid) {
            this.setState({
                recaptchaerror: ''
            });
            try {
                const result = await sendUsername(username);
                if (!result) throw result;
                // console.log(result);
                if (result.status === 200) {
                    this.setState({ submituser: false });
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            // this.setState({
            //     recaptchaerror: 'Recaptcha Required'
            // });
            return;
        }
        this.setState({ submituser: false });
    };

    changePassword = async (e) => {
        e.preventDefault();
        const { username, verificationcode, confpassword } = this.state;
        const data = Object.freeze({ username: username, verificationcode: verificationcode, newpassword: confpassword });
        // console.log(data);
        let formFileds = [
            "verificationcode",
            "confpassword",
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });

        if (isValid) {
            try {
                const result = await forgotPassword(data);
                if (!result) throw result;
                if (result.status === 200) {
                    this.props.history.push(`/`);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            return false;
        }
    };

    backToPrivios = async (e) => {
        e.preventDefault();
        this.setState({ submituser: true });
    };
    render() {
        const SubmitUser = this.state.submituser;
        return (
            <React.Fragment>
                <ForgetpasswordWrapper>
                    <div className="container-fluid col-md-12 bg-color d-flex justify-content-center align-items-center">
                        <div className='row'>
                            <div className="login-container">
                                <div className='row'>
                                    <div class="col-md-6">
                                        <div className='forgotpassword-bg'></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div className='loginform'>
                                            <img className='loginleftsideimg'
                                                src={'images/ksr/login/ksr-logo.png'}
                                                className="img-fluid"
                                                alt="Ksr"
                                            />
                                            <h4 className='login-text'>forgot password</h4>
                                            {SubmitUser ? (
                                                <form onSubmit={(e) => this.usernameSubmit(e)} autoComplete="off">
                                                    <p style={{ margin: '10px 0 20px', fontSize: '12px' }}>
                                                        Please enter your User Name
                                                </p>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="username"
                                                            id="username"
                                                            placeholder="User Name"
                                                            value={this.state.username}
                                                            onBlur={(e) => this.handleBlur(e)}
                                                            autoComplete="off"
                                                            onChange={(e) => this.handleUserInput(e)}
                                                        />
                                                        <br />
                                                        {this.state.usernameerror && (
                                                            <div className="errorMsg">{this.state.usernameerror}</div>
                                                        )}
                                                        <span className="icon_placeholder">
                                                            <i className="far fa-envelope"></i>
                                                        </span>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <ReCAPTCHA
                                                            sitekey={`${RecaptchaKey}`}
                                                            onChange={(e) => this.recaptchaValidate(e)}
                                                        />
                                                        {this.state.recaptchaerror && (
                                                            <div className="errorMsg">{this.state.recaptchaerror}</div>
                                                        )}
                                                    </div> */}

                                                    <button type="submit" className="btn-submit">
                                                        Submit
                                                </button>
                                                    <Link to="/" style={{ float: 'right', marginTop: '20px' }}>
                                                        Back to Login
                                                </Link>
                                                </form>
                                            ) : (
                                                    <form onSubmit={(e) => this.changePassword(e)} autoComplete="off">
                                                        <p style={{ margin: '10px 0 20px' }}>
                                                            Enter the code sent to your registered email
                                                    </p>
                                                        <h6 style={{ marginTop: '22px' }}>Verification code</h6>
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                maxLength={6}
                                                                className="form-control"
                                                                autoComplete="new-password"
                                                                autoCorrect="off"
                                                                spellCheck="off"
                                                                id="verificationcode"
                                                                name="verificationcode"
                                                                placeholder="Verification Code"
                                                                onBlur={(e) => this.handleBlur(e)}
                                                                onChange={(e) => this.handleUserInput(e)}
                                                            />
                                                            {this.state.verificationcodeerror && (
                                                                <div className="errorMsg">{this.state.verificationcodeerror}</div>
                                                            )}
                                                            <span className="icon_placeholder">
                                                                <i className="far fa-comment-dots"></i>
                                                            </span>
                                                        </div>
                                                        <h6 style={{ marginTop: '22px' }}>New Password</h6>
                                                        <div className="form-group">
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                className="form-control"
                                                                placeholder="New Password"
                                                                onChange={(e) => this.passwordValidate(e)}
                                                            />
                                                        </div>
                                                        <div id="message" style={{ display: 'none' }}>
                                                            <div className="triangle-up"></div>
                                                            <p id="letter" className="invalid">
                                                                Password must have One Lowercase
                                                        </p>
                                                            <p id="capital" className="invalid">
                                                                Password must have One Uppercase
                                                        </p>
                                                            <p id="number" className="invalid">
                                                                Password must have One Number
                                                        </p>
                                                            <p id="specialChareacter" className="invalid">
                                                                Password must have One Special Character
                                                        </p>
                                                            <p id="length" className="invalid">
                                                                Password should have Minimum 8 Character
                                                        </p>
                                                        </div>
                                                        <h6 style={{ marginTop: '22px' }}>Conform Password</h6>
                                                        <div className="form-group">
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="confpassword"
                                                                name="confpassword"
                                                                placeholder="Confirm Password"
                                                                onBlur={(e) => this.handleBlur(e)}
                                                                onChange={(e) => this.confirmPassword(e)}
                                                            />
                                                            {this.state.passwordConfirmationError && (
                                                                <div className="errorMsg">{this.state.passwordConfirmationError}</div>
                                                            )}
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <button type="submit" disabled={!this.state.confirmPasswordValidate} className="btn-submit">
                                                            Submit
                                                    </button>
                                                        <p>
                                                            <a
                                                                style={{ float: 'left', marginTop: '21px' }}
                                                                onClick={(e) => this.backToPrivios(e)}
                                                            >
                                                                Back to Previous
                                                        </a>
                                                            <Link to="/" style={{ float: 'right', marginTop: '21px' }}>
                                                                Back to Login
                                                        </Link>
                                                        </p>
                                                    </form>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ForgetpasswordWrapper>
            </React.Fragment>
        );
    }
}

const ForgetpasswordWrapper = styled.div`
.bg-color {
  height: 100vh;
  width: 100%;
  background: #EEEEEE 0% 0% no-repeat padding-box;
}
.login-container {
  width: 800px;
  min-height: 545px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 18px #00000026;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.login-text {
  margin-top: 22px;
  text-transform: uppercase;
  text-align: left;
}
.forgetpassword {
  color: #ED3125;
  text-transform: capitalize;
  text-align: right;
}
.btn-submit{
  margin-left: 9px;
  margin-top: 13px;
  width: 350px;
  height: 50px;
  background: #ED3125 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: white;
  border: 0
}
.powerdby {
  margin: 0 auto;
  margin-top: 25px;
  width: 300px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 12px;
  text-align: center;
}
.powerdby img {
  padding: 0 10px;
}
.loginform {
  margin: 0 auto;
  margin-top: 25px;
}

::placeholder {
  color: red !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: red;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: red;
}
a:hover {
  color: #ED3125 !important;
  text-decoration: none !important;
}

a {
  color: #ED3125 !important;
}
`

export default ForgotPassword;
