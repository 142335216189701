import React from 'react';
import FormMiddleware from './../middlewares/FormMiddleware';
import { crypto } from './../middlewares/Utils';
import { login } from '../middlewares/User';
import { Link } from 'react-router-dom';
import http from '../config/axiosConfig';
import styled from 'styled-components';
import Loader from '../components/Loader';

class Login extends FormMiddleware {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      usernameerror: '',
      passworderror: '',
      loader: false,
      loginErrorMsg: ''
    };
  }
  componentDidMount() {
    localStorage.clear();
  }

  formSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loader: true
    })
    const { username, password } = this.state;
    const encPassword = crypto(password);
    const json = Object.freeze({
      username: username,
      password: encPassword.toString(),
    });
    let formFileds = ['username', 'password'];
    let isValid = true;
    formFileds.forEach((field) => {
      isValid = this.validateField(field) && isValid;
    });

    if (isValid) {
      localStorage.setItem('username', username)
      try {
        const result = await login(json);
        console.log('result', result);
        this.setState({
          loginErrorMsg: ''
        });
        if (!result) throw result;
        // console.log(result);
        if (result.status === 200) {
          if (result.data.message && result.data.message === 'GO_TO_RESET_PAGE') {
            this.props.history.push(`/changepassword`);
          } else {
            this.setState({
              loader: false
            })
            localStorage.setItem('access_token', result.headers.access_token);
            localStorage.setItem(
              'refreshtoken_token',
              result.headers.refreshtoken_token
            );
            localStorage.setItem(
              'authorizedtoken',
              result.headers.authorizedtoken
            );
            localStorage.setItem('userDetails', JSON.stringify(result.data));
            localStorage.setItem('baseUrl', process.env.REACT_APP_API_URL);
            // const [body] = document.getElementsByTagName('body');
            // const script = document.createElement('script');
            // script.src = './js/refresh.js';
            // body.appendChild(script);
            // http.defaults.headers.common['access_token'] =
            //   result.headers.access_token;
            // http.defaults.headers.common['authorizedtoken'] =
            //   result.headers.authorizedtoken;
            this.props.history.push(`/location360`);
          }
        } else {
          this.props.history.push(`/`);
        }
      } catch (error) {
        this.props.history.push(`/`);
        if (error.response) {
          const data = error.response.data.error
          this.setState({
            loginErrorMsg: data.message
          })
        }
        console.error(error);
      }
    } else {
      return;
    }
  };
  render() {
    return (
      <React.Fragment>
        <LoginWrapper>
          {
            // this.state.loader && <Loader />
          }
          <div className="container-fluid col-md-12 bg-color d-flex justify-content-center align-items-center">
            <div className='row'>
              <div className="login-container">
                <div className='row'>
                  <div className="col-md-6">
                    <div className='login-bg'></div>
                  </div>
                  <div className="col-md-6">
                    <div className='loginform'>
                      <form onSubmit={(e) => this.formSubmit(e)}>
                        <img className='loginleftsideimg'
                          src={'images/ksr/login/ksr-logo.png'}
                          className="img-fluid"
                          alt="Ksr"
                        />
                        <h4 className='login-text'>login</h4>
                        <h6 style={{ marginTop: '22px' }}>Username</h6>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="User Name"
                            name="username"
                            onBlur={(e) => this.handleBlur(e)}
                            onChange={(e) => this.handleUserInput(e)}
                          />
                          {this.state.usernameerror && (
                            <div className="errorMsg">{this.state.usernameerror}</div>
                          )}
                          <span className="icon_placeholder">
                            {' '}
                            <i className="far fa-user"></i>{' '}
                          </span>
                        </div>
                        <h6>Password</h6>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Password"
                            autoComplete="new-password"
                            onBlur={(e) => this.handleBlur(e)}
                            onChange={(e) => this.handleUserInput(e)}
                          />
                          {this.state.passworderror && (
                            <div className="errorMsg">{this.state.passworderror}</div>
                          )}
                          <span className="icon_placeholder">
                            {' '}
                            <i className="fas fa-lock"></i>{' '}
                          </span>
                          <span className="icon_placeholder password_eyeclose">
                            <i className="far fa-eye"></i>
                          </span>
                          <span className="icon_placeholder password_eyeclose">
                            <i className="fas fa-eye-slash"></i>
                          </span>
                        </div>
                        <div className='forgetpassword'>
                          <Link to={{ pathname: "/forgotpassword" }}>forgot password?</Link>
                        </div>
                        {this.state.loginErrorMsg && (
                          <div className="errorMsg" style={{ marginLeft: '53px', marginTop: '8px', fontSize: '14px' }}><b>{this.state.loginErrorMsg}</b></div>
                        )}
                        <input
                          type="submit"
                          id="loginSubmit"
                          value="Sign in"
                          className="btn-submit"
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className='powerdby d-flex justify-content-center align-items-center'>
                  Powered by:
                  <img
                    src={'images/ksr/login/goldfinch.png'}
                    className="img-fluid"
                    alt="Ksr"
                  />
                  <img
                    src={'images/ksr/login/IoTCo.png'}
                    className="img-fluid"
                    alt="Ksr"
                  />
                </div>
              </div>
            </div>
          </div>
        </LoginWrapper>
      </React.Fragment>
    );
  }
}

const LoginWrapper = styled.div`
.bg-color {
  height: 100vh;
  width: 100%;
  background: #EEEEEE 0% 0% no-repeat padding-box;
}
.login-container {
  width: 800px;
  height: 500px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 18px #00000026;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.login-text {
  margin-top: 22px;
  text-transform: uppercase;
  text-align: left;
}
.forgetpassword {
  color: #ED3125;
  text-transform: capitalize;
  text-align: right;
}
a:hover {
  color: #ED3125 !important;
  text-decoration: none !important;
}

a {
  color: #ED3125 !important;
}
.btn-submit{
  margin-top: 25px;
  width: 350px;
  height: 50px;
  background: #ED3125 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: white;
  border: 0
}
.powerdby {
  margin: 0 auto;
  margin-top: 25px;
  width: 300px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 12px;
  text-align: center;
}
.powerdby img {
  padding: 0 10px;
}
.loginform {
  margin: 0 auto;
  margin-top: 25px;
}

form .form-control::-webkit-input-placeholder { 
  color: #4CAF50;
}

form .form-control::-moz-placeholder {
  color: #4CAF50;
}
form .form-control:-ms-input-placeholder {
  color: #4CAF50;
}
form .form-control:placeholder {
  color: #4CAF50;
}
`

export default Login;
