import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // headers: {
  //   access_token: localStorage.getItem('access_token')
  //     ? localStorage.getItem('access_token')
  //     : '',
  //   authorizedtoken: localStorage.getItem('authorizedtoken')
  //     ? localStorage.getItem('authorizedtoken')
  //     : '',
  // },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const data = error.response;
      // console.log('status', data.status);
      if (data.status === 401) {
        window.location.href = '/'
      }
    }
    return Promise.reject(error);
  }
);

export const headers = () => {
  return {
    access_token: localStorage.getItem('access_token'),
    authorizedtoken: localStorage.getItem('authorizedtoken'),
  }
};

export const localstorage = () => {
  return JSON.parse(localStorage.getItem('userDetails'));
};

export default http;
