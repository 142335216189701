import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DownloadCsvFile from '../../components/DownloadCsvFile';
import * as Toasts from '../../components/Toasts';
import { locationFilter } from '../../middlewares/Location360Api';
import { userFilter } from '../../middlewares/Employee360Api';
import LineChart from '../../components/charts/LineChart';
import Select from "react-select";
import moment from 'moment';
import { scannedChartData, exportData } from '../../middlewares/Trend360Api';

class Trend360 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startdate: new Date(moment().subtract(7, 'd').format()),
            enddate: new Date(),
            choosedate: '',
            location: [],
            sacnnedChartData: [],
            locationList: [],
            deviceList: [],
            salarystaf: 0,
            hourlystaf: 0,
            exportData: [],
            tableData: [],
            tableLocation: [],
            tableDevice: [],
            tableType: [],
            tableAccess: [],
            droplocation: 'all',
            dropdevice: 'all',
            droptype: 'all',
            dropaccess: 'all',
            tableVisiterState: [],
            dropVisiterState: 'all',
            UserList: [],
            user: []
        }
    }

    componentDidMount() {
        this.getLocationFilterData();
        this.getUserList();
        this.submitFilter();
    }

    submitFilter = () => {
        const { startdate, enddate, location, user } = this.state;
        startdate.setHours(0, 0, 0, 0);
        enddate.setHours(23, 59, 59, 0);
        let json = {};
        if (startdate || enddate) {
            json.startdate = moment(startdate).utc().format();
            json.enddate = moment(enddate).utc().format();
        }

        if (user.length > 0) {
            json.user = user.map(item => item.value).join(',');
        }

        if (location.length > 0) {
            json.location = location.map(item => item.value).join(',');
        }

        if (json) {
            this.getScannedChartData(json);
            this.getExportData(json);
        }
    }

    getScannedChartData = async (input) => {
        try {
            const data = await scannedChartData(Object.freeze(input));
            console.log('====>', data);
            if (data.status === 200) {
                const chartData = data.data;
                let formatData = [];
                if (chartData && chartData.length) {
                    const unique = [...new Set(chartData.map(item => item.createtime))];
                    // console.log('unique=>', unique);
                    unique.map(date => {
                        const filterData = chartData.filter(item => item.createtime === date);
                        let finalFilter = {
                            "date": date,
                            "value": 0,
                            "value1": 0,
                            "value2": 0,
                            "value3": 0,
                        };
                        // console.log('------->', filterData);
                        filterData.map(item => {
                            if (item.visitstatename === 'Normal') {
                                finalFilter.value = +item.count;
                            } else if (item.visitstatename === "Abnormal Temperature") {
                                finalFilter.value1 = +item.count;
                            } else if (item.visitstatename === 'Abnormal Questionnaire') {
                                finalFilter.value3 = +item.count;
                            } else if (item.visitstatename === 'Access Fail') {
                                finalFilter.value2 = +item.count;
                            }
                        });
                        // console.log('------->', finalFilter);
                        formatData.push(finalFilter);
                    })
                    // console.log("***************");
                    // console.log('finalData=>', formatData);
                }
                this.setState({
                    sacnnedChartData: formatData.length ? formatData : []
                });
            }
        } catch (error) {

        }
    }

    getLocationFilterData = async () => {
        try {
            const data = await locationFilter();

            if (data.status === 200) {
                let LocationList = [];
                if (data.data.length) {
                    LocationList = data.data.map(value => {
                        return {
                            label: value.location,
                            value: value.location
                        };
                    });
                    // LocationList.unshift({ id: 'all', locationname: 'All' });
                }
                this.setState({
                    locationList: LocationList,
                });
                // console.log(data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    getUserList = async () => {
        try {
            const data = await userFilter();
            if (data.status === 200) {
                let userList = [];
                if (data.data.length) {
                    userList = data.data.map(value => {
                        return {
                            label: value.visitorname,
                            value: value.visitorname
                        };
                    });
                    // userList.unshift({ id: 'all', username: 'All' });
                }
                this.setState({
                    UserList: userList,
                });
                // console.log(data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    changeLocation = (opt, { option }) => {
        console.log('opt=>', opt);
        console.log('option=>', option);
        if (opt) {
            this.setState({
                location: opt
            });
        } else {
            this.setState({
                location: []
            });
        }
    }

    changeUser = (opt, { option }) => {
        console.log('opt=>', opt);
        console.log('option=>', option);
        if (opt) {
            this.setState({
                user: opt
            });
        } else {
            this.setState({
                user: []
            });
        }
    }

    fromDateChange = date => {
        // console.log(date);
        this.setState({
            startdate: date
        });
    };

    toDataChange = date => {
        this.setState({
            enddate: date
        });
    }

    clearInputs = () => {
        this.setState({
            startdate: new Date(moment().subtract(7, 'd').format()),
            enddate: new Date(),
            user: [],
            location: []
        });
        setTimeout(() => {
            this.submitFilter();
        }, 200)

    }

    getExportData = async (json) => {
        try {
            const data = await exportData(Object.freeze(json));
            // console.log('export==>', data);
            if (data.status === 200 && data.data.length > 0) {
                const Device = [...new Set(data.data.map(item => item.devicename))];
                const Location = [...new Set(data.data.map(item => item.locationname))];
                const Type = [...new Set(data.data.map(item => item.visitortype))];
                const Access = [...new Set(data.data.map(item => item.access))];
                const VisiterSate = [...new Set(data.data.map(item => item.visitstatename))];
                data.data.sort(function (a, b) {
                    return new Date(b.createtimewithtime) - new Date(a.createtimewithtime);
                });
                const tableVisiterState = VisiterSate.map(value => {
                    return {
                        id: value,
                        visiterstatename: value
                    };
                });
                tableVisiterState.unshift({ id: 'all', visiterstatename: 'Visitor State' });
                const tableDevice = Device.map(value => {
                    return {
                        id: value,
                        devicename: value
                    };
                });
                tableDevice.unshift({ id: 'all', devicename: 'Device' });
                const tableLocation = Location.map(value => {
                    return {
                        id: value,
                        locationname: value
                    };
                });
                tableLocation.unshift({ id: 'all', locationname: 'Location' });
                const tableType = Type.map(value => {
                    return {
                        id: value,
                        typename: value
                    };
                });
                tableType.unshift({ id: 'all', typename: 'Type' });
                const tableAccess = Access.map(value => {
                    return {
                        id: value,
                        accessname: value
                    };
                });
                tableAccess.unshift({ id: 'all', accessname: 'Access' });

                this.setState({
                    exportData: data.data,
                    tableData: data.data,
                    tableDevice: tableDevice,
                    tableLocation: tableLocation,
                    tableType: tableType,
                    tableAccess: tableAccess,
                    tableVisiterState: tableVisiterState
                })
            } else {
                this.setState({
                    exportData: [],
                    tableData: [],
                    tableDevice: [],
                    tableLocation: [],
                    tableType: [],
                    tableAccess: [],
                    tableVisiterState: []
                })
            }
        } catch (error) {

        }
    }

    getCsvFile = async () => {
        const { exportData } = this.state;
        if (exportData.length > 0) {
            const keys = Object.keys(exportData[0]);
            let headers = {}
            keys.map(item => {
                headers[item] = item.replace(/,/g, '')
            });
            const itemsNotFormatted = exportData;

            const itemsFormatted = [];
            itemsNotFormatted.forEach((item) => {
                itemsFormatted.push({
                    visitstatename: item.visitstatename ? item.visitstatename.replace(/,/g, '') : '',
                    access: item.access ? item.access.replace(/,/g, '') : '',
                    clockno: item.clockno ? item.clockno.replace(/,/g, '') : '',
                    createtimewithtime: item.createtimewithtime ? this.formatDate(item.createtimewithtime.replace(/,/g, '')) : '',
                    devicename: item.devicename ? item.devicename.replace(/,/g, '') : '',
                    locationname: item.locationname ? item.locationname.replace(/,/g, '') : '',
                    name: item.name ? item.name.replace(/,/g, '') : '',
                    temperature: item.temperature ? item.temperature.replace(/,/g, '') : '',
                    visitortype: item.visitortype ? item.visitortype.replace(/,/g, '') : ''
                });
            });

            var fileTitle = 'trend360'; // or 'my-unique-title'
            await DownloadCsvFile(headers, itemsFormatted, fileTitle);
        } else {
            Toasts.Info('Record Not Found!');
        }
    }

    exportTable = async () => {
        const { tableData } = this.state;

        if (tableData.length > 0) {
            const keys = Object.keys(tableData[0]);
            let headers = {}
            keys.map(item => {
                headers[item] = item.replace(/,/g, '')
            });
            const itemsNotFormatted = tableData;
            const itemsFormatted = [];
            itemsNotFormatted.forEach((item) => {
                itemsFormatted.push({
                    visitstatename: item.visitstatename ? item.visitstatename.replace(/,/g, '') : '',
                    access: item.access ? item.access.replace(/,/g, '') : '',
                    clockno: item.clockno ? item.clockno.replace(/,/g, '') : '',
                    createtimewithtime: item.createtimewithtime ? this.formatDate(item.createtimewithtime.replace(/,/g, '')) : '',
                    devicename: item.devicename ? item.devicename.replace(/,/g, '') : '',
                    locationname: item.locationname ? item.locationname.replace(/,/g, '') : '',
                    name: item.name ? item.name.replace(/,/g, '') : '',
                    temperature: item.temperature ? item.temperature.replace(/,/g, '') : '',
                    visitortype: item.visitortype ? item.visitortype.replace(/,/g, '') : ''
                });
            });

            var fileTitle = 'trend360Table'; // or 'my-unique-title'
            await DownloadCsvFile(headers, itemsFormatted, fileTitle);
        } else {
            Toasts.Info('Record Not Found!');
        }

    }


    formatDate = (date) => {
        const fomratDate = new Date(date);
        return moment(fomratDate).format('YYYY-MM-DD H:mm:ss');
    }

    filterTableValue = (e, type) => {
        // console.log('value=', e.target.value);
        // console.log('type', type);
        if (e.target.value === 'all') {
            return;
        } else {
            if (type === 'location') {
                this.setState({
                    droplocation: e.target.value,
                });
            } else if (type === 'device') {
                this.setState({
                    dropdevice: e.target.value,
                });
            } else if (type === 'type') {
                this.setState({
                    droptype: e.target.value,
                });
            } else if (type === 'access') {
                this.setState({
                    dropaccess: e.target.value,
                });
            } else if (type === 'visiterstate') {
                this.setState({
                    dropVisiterState: e.target.value
                });
                // filterdata = exportData.filter(item => item.visitstatename === e.target.value);
            }
            setTimeout(() => {
                this.setFilterData();
            }, 500);
        }

    }

    setFilterData = () => {
        const { exportData, droplocation, dropdevice, droptype, dropaccess, dropVisiterState } = this.state;
        let Obj = {};

        if (droplocation && droplocation !== 'all') {
            Obj.locationname = droplocation;
        }

        if (dropdevice && dropdevice !== 'all') {
            Obj.devicename = dropdevice;
        }

        if (droptype && droptype !== 'all') {
            Obj.visitortype = droptype;
        }

        if (dropaccess && dropaccess !== 'all') {
            Obj.access = dropaccess;
        }

        if (dropVisiterState && dropVisiterState !== 'all') {
            Obj.visitstatename = dropVisiterState;
        }

        // console.log('Obj=>', Obj);
        let Data = exportData;
        for (const [key, value] of Object.entries(Obj)) {
            console.log(Data);
            Data = Data.filter((item) => {
                if (item[key] === value) {
                    return item;
                }
            });
        }
        // console.log('filter=>', Data);

        this.setState({
            tableData: Data
        });
    }

    reSetTable = () => {
        const { exportData } = this.state;
        this.setState({
            droplocation: 'all',
            dropdevice: 'all',
            droptype: 'all',
            dropaccess: 'all',
            dropVisiterState: 'all'
        });
        this.setState({
            tableData: exportData
        });
    }

    customStyles = {
        container: base => ({
            ...base,
            width: '192px',
            marginTop: '9px',
        }),
        option: base => ({
            ...base,
            color: '#000'
        }),
        control: base => ({
            ...base,
            background: '#646464',
            border: '1px solid #FC6157',
            borderRadius: '3px',
        })
    }
    render() {
        const { locationList, UserList, sacnnedChartData, tableLocation, tableVisiterState, tableDevice
            , tableAccess, tableType, tableData } = this.state;
        return (
            <React.Fragment>
                <div className="main-contnet">
                    <div>
                        <div>
                            <div>
                                <div className="content">
                                    <div className="title">
                                        <h5 style={{ fontWeight: "600" }}>Trend 360</h5>
                                    </div>
                                    <hr className="hrline" />
                                    <div className="filter">
                                        <div className="btn-group">
                                            <div style={{ paddingLeft: "33px" }}>
                                                <label style={{ fontSize: "12px" }}>From Date</label>
                                                <br />
                                                <DatePicker
                                                    selected={this.state.startdate}
                                                    onChange={this.fromDateChange}
                                                />
                                            </div>
                                            <div style={{ paddingLeft: "12px" }}>
                                                <label style={{ fontSize: "12px" }}>To Date</label>
                                                <br />
                                                <DatePicker
                                                    selected={this.state.enddate}
                                                    onChange={this.toDataChange}
                                                />
                                            </div>
                                            <div style={{ paddingLeft: "12px" }}>
                                                <label style={{ fontSize: "12px" }}>Location</label>
                                                <br />
                                                <Select
                                                    isMulti
                                                    onChange={this.changeLocation}
                                                    options={locationList}
                                                    value={this.state.location}
                                                    styles={this.customStyles}
                                                />
                                            </div>
                                            <div style={{ paddingLeft: "12px" }}>
                                                <label style={{ fontSize: "12px" }}>User Type</label>
                                                <br />
                                                <Select
                                                    isMulti
                                                    onChange={this.changeUser}
                                                    options={UserList}
                                                    value={this.state.user}
                                                    styles={this.customStyles}
                                                />
                                            </div>
                                            <span className='export-btn'>
                                                <button type='button' style={{ width: '57px', marginRight: '3px', marginTop: '40px', marginLeft: '12px' }} onClick={this.submitFilter}>Apply</button>
                                            </span>
                                            <span className='export-btn'>
                                                <button type='button' style={{ width: '57px', marginRight: '3px', marginTop: '40px' }} onClick={this.clearInputs}>Clear</button>
                                            </span>
                                        </div>
                                        <span className='export-btn'>
                                            <button type='button' style={{ marginRight: '11px', marginTop: '40px' }} onClick={this.getCsvFile}>Export</button>
                                        </span>
                                    </div>
                                </div>
                                <div className="passesAlign">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <span className='hedingtitle'>Scanned</span>
                                            </div>
                                            <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                                            <div className="passesAppliedMain">
                                                <p style={{ color: "white" }}>{sacnnedChartData.length > 0 ?
                                                    <LineChart data={sacnnedChartData} chartid={'trend'} />
                                                    : <div style={{ marginLeft: '407px', color: '#fff', marginTop: '68px', marginBottom: '40px' }}>No Data Found</div>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="passesAlign">
                                    <div className="row" >
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <span className='hedingtitle'>Scanned Records {'(' + tableData.length + ')'}</span>
                                                <div className="d-flex align-items-center">
                                                    <div className='buttonfilter' style={{ backgroundColor: '#ed3125' }} onClick={this.exportTable}>Export</div>
                                                    <div className='buttonfilter' onClick={this.reSetTable}>Reset</div>
                                                </div>
                                            </div>
                                            <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                                            {tableData.length > 0 ?
                                                <div className="adminMainTable">
                                                    <table >
                                                        <thead>
                                                            <tr>
                                                                <th>Clock No.</th>
                                                                <th>Name</th>
                                                                <th>Date & Time</th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.droplocation}
                                                                    onChange={(e) => this.filterTableValue(e, 'location')}
                                                                >
                                                                    {tableLocation &&
                                                                        tableLocation.length &&
                                                                        tableLocation.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.locationname}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                {/* style={{ marginLeft: '10px', paddingLeft: '10px' }} */}
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.dropdevice}
                                                                    onChange={(e) => this.filterTableValue(e, 'device')}
                                                                >
                                                                    {tableDevice &&
                                                                        tableDevice.length &&
                                                                        tableDevice.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.devicename}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.droptype}
                                                                    onChange={(e) => this.filterTableValue(e, 'type')}
                                                                >
                                                                    {tableType &&
                                                                        tableType.length &&
                                                                        tableType.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.typename}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.dropaccess}
                                                                    onChange={(e) => this.filterTableValue(e, 'access')}
                                                                >
                                                                    {tableAccess &&
                                                                        tableAccess.length &&
                                                                        tableAccess.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.accessname}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.dropVisiterState}
                                                                    onChange={(e) => this.filterTableValue(e, 'visiterstate')}
                                                                >
                                                                    {tableVisiterState &&
                                                                        tableVisiterState.length &&
                                                                        tableVisiterState.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.visiterstatename}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th>Temp</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id='style-7'>
                                                            {tableData.map((value, index) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr key={index}>
                                                                            <td>{value.clockno}</td>
                                                                            <td>{value.name}</td>
                                                                            {/* <td>{value.createtimewithtime}</td> */}
                                                                            <td>{this.formatDate(value.createtimewithtime)}</td>
                                                                            <td>{value.locationname}</td>
                                                                            <td>{value.devicename}</td>
                                                                            <td>{value.visitortype}</td>
                                                                            <td>{value.access}</td>
                                                                            <td>{value.visitstatename}</td>
                                                                            <td>{value.temperature}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : <div style={{ marginLeft: '436px', color: '#fff', marginTop: '68px', marginBottom: '40px' }}>No Data Found</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
}

export default Trend360;