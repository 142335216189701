const CryptoTS = require('crypto-ts');

const crypto = (text) => {
  const result = CryptoTS.AES.encrypt(
    text,
    process.env.REACT_APP_CRYPTO_SECRET
  );
  return result;
};

export { crypto };
