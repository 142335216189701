import http from '../config/axiosConfig';

export const login = async (userDetails) => {
  const request = await http.post('/users/login', userDetails);
  return request;
};

export const sendUsername = async (Username) => {
  const request = await http.get(`users/forgotpassword/${Username}`);
  return request;
};

export const forgotPassword = async (data) => {
  const request = await http.post('users/updatepassword', data);
  return request;
};

export const changePassword = async (data) => {
  const request = await http.post('admin/user/changepassword', data);
  return request;
}

export const conformVerification = async (data) => {
  const request = await http.post('users/confirmregistration', data);
  return request;
}