import React, { Component } from 'react';
import { QueryBuilder } from "@cubejs-client/react";
import { Redirect } from 'react-router-dom';
import cubejsApi from '../config/cubejsApi';
import ColumnWithLineChart from '../components/charts/ColumnWithLineChart';
import ClusteredBarChart from '../components/charts/ClusteredBarChart';
import PieChart from '../components/charts/PieChart';
import moment from 'moment';

const Authtoken = window.localStorage.getItem('authorizedtoken');

console.log('-----------');
console.log(Authtoken);

// if (!Authtoken) {
//   delete axios.defaults.headers.common['access_token']; 
//   delete axios.defaults.headers.common['authorizedtoken'];
//   console.log('inside');
// }
class DashboardPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dailyTrendQuery: {},
      cumulativeTrendQuery: {}
    }
  }
  componentDidMount() {
    this.setState({
      cumulativeTrendQuery: {
        measures: [
          'CountywiseCaseTrends.TotalDeaths',
          'CountywiseCaseTrends.TotalReportedCases'
        ],
        timeDimensions: [{
          dimension: 'CountywiseCaseTrends.reportedDate',
          dateRange: ['2020-05-14', '2020-05-20'],
          // granularity: 'month'
        }],
        dimensions: [
          'CountywiseCaseTrends.reportedDate'
        ],
        filters: [],
        order: {
          'CountywiseCaseTrends.reportedDate': 'asc'
        }
      },
      dailyTrendQuery: {
        measures: [
          'CountywiseCaseTrends.NewDeaths',
          'CountywiseCaseTrends.NewReportedCases'
        ],
        timeDimensions: [{
          dimension: 'CountywiseCaseTrends.reportedDate',
          dateRange: ['2020-05-14', '2020-05-20'],
          // granularity: 'month'
        }],
        dimensions: [
          'CountywiseCaseTrends.reportedDate'
        ],
        'filters': [],
        order: {
          'CountywiseCaseTrends.reportedDate': 'asc'
        },
      }
    });
  }

  capitalize = ([first, ...rest]) =>
    first.toUpperCase() + rest.join('').toLowerCase()

  dateFormatter = ({ x }) => moment(x).format('MMM DD');
  render() {
    const { dailyTrendQuery, cumulativeTrendQuery } = this.state;
    return (
      <React.Fragment>
        <div className='row' style={{ marginTop: '78px' }}>
          <div className='col-6'>
            {dailyTrendQuery && (
              <QueryBuilder
                query={dailyTrendQuery}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  if (!resultSet) {
                    return 'Loding....'
                  }
                  // console.log(resultSet.chartPivot());
                  // console.log(resultSet.seriesNames());
                  const data = resultSet.chartPivot().map((element) => {
                    let a = {};
                    a = {
                      'date': moment(element['category']).format('MMM DD'),
                      'Death': element['CountywiseCaseTrends.NewDeaths'],
                      'Total case': element['CountywiseCaseTrends.NewReportedCases']
                    };
                    return a;
                  });
                  return (
                    <ColumnWithLineChart
                      resultSet={data} axisleftTitle={'Total'}
                      axisrightTitle={'Count'}
                      catagory={'date'}
                      coumnChartName={'Total case'}
                      lineChartname={'Death'}
                      title={'Daily Trend'}
                      chartid={'daily'}
                    />
                  );
                }}
              />
            )}
          </div>
          <div className='col-6'>
            {cumulativeTrendQuery && (
              <QueryBuilder
                query={cumulativeTrendQuery}
                cubejsApi={cubejsApi}
                render={({ resultSet }) => {
                  if (!resultSet) {
                    return 'Loading...';
                  }
                  // console.log(resultSet.chartPivot());
                  // console.log(resultSet.seriesNames());
                  const data = resultSet.chartPivot().map((element) => {
                    let a = {};
                    a = {
                      'date': moment(element['category']).format('MMM DD'),
                      'Death': element['CountywiseCaseTrends.TotalDeaths'],
                      'Total case': element['CountywiseCaseTrends.TotalReportedCases']
                    };
                    return a;
                  });
                  return (
                    <ColumnWithLineChart
                      resultSet={data} axisleftTitle={'Total'}
                      axisrightTitle={'Count'}
                      catagory={'date'}
                      coumnChartName={'Total case'}
                      lineChartname={'Death'}
                      title={'Cumulative Trend'}
                      chartid={'cumulative'}
                    />
                  );
                }}
              />
            )}
          </div>
          <div className='row' style={{ marginTop: '78px' }}>
            <div className='col-6'>
              {dailyTrendQuery && (
                <QueryBuilder
                  query={dailyTrendQuery}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) {
                      return 'Loding....'
                    }
                    // console.log(resultSet.chartPivot());
                    // console.log(resultSet.seriesNames());
                    const data = resultSet.chartPivot().map((element) => {
                      let a = {};
                      a = {
                        'date': moment(element['category']).format('MMM DD'),
                        'Death': element['CountywiseCaseTrends.NewDeaths'],
                        'Total case': element['CountywiseCaseTrends.NewReportedCases']
                      };
                      return a;
                    });
                    return (
                      <PieChart
                        resultSet={data}
                        catagory={'date'}
                        pie1ChartName={'Total case'}
                        pie2Chartname={'Death'}
                        title={'Daily Trend'}
                        chartid={'dailypie'}
                      />
                    );
                  }}
                />
              )}
            </div>
            <div className='col-6'>
              {cumulativeTrendQuery && (
                <QueryBuilder
                  query={cumulativeTrendQuery}
                  cubejsApi={cubejsApi}
                  render={({ resultSet }) => {
                    if (!resultSet) {
                      return 'Loding....'
                    }
                    // console.log(resultSet.chartPivot());
                    // console.log(resultSet.seriesNames());

                    const data = resultSet.chartPivot().map((element) => {
                      let a = {};
                      a = {
                        'date': moment(element['category']).format('MMM DD'),
                        'Death': element['CountywiseCaseTrends.TotalDeaths'],
                        'Total case': element['CountywiseCaseTrends.TotalReportedCases']
                      };
                      return a;
                    });

                    return (
                      <ClusteredBarChart
                        resultSet={data}
                        catagory={'date'}
                        bar1={'Total case'}
                        bar2={'Death'}
                        title={'Cumulative Trend'}
                        chartid={'cumulative12'}
                      />
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardPage;
