import http, { headers } from '../config/axiosConfig';


export const roleList = async (offset, limit) => {
  const request = await http.get(
    `/admin/petram/UserRole/list/${offset}/${limit}`, { headers: headers() }
  );
  return request;
};

export const roleOrganization = async () => {
  const request = await http.get(
    `/admin/Organizations?filter[where][status]=A`, { headers: headers() }
  );
  return request;
};

export const userRoleList = async () => {
  const request = await http.get(`/admin/petram/UserRole`, { headers: headers() });
  return request;
};

export const locationList = async () => {
  const request = await http.get(`/admin/organizationlocation`, { headers: headers() });
  return request;
};

export const businessList = async (offset, limit) => {
  const request = await http.get(`/admin/Groups/list/${offset}/${limit}`, { headers: headers() });
  return request;
};

export const addBusiness = async (data) => {
  const request = await http.post('admin/Groups', data, { headers: headers() });
  return request;
};

export const saveUserRole = async (data) => {
  const request = await http.post(`admin/UserRole`, data, { headers: headers() });
  return request;
};

export const getBusinnesById = async (id) => {
  const request = await http.get(`admin/Groups/${id}`, { headers: headers() });
  return request;
};

export const updateBuisnessUnit = async (data, id) => {
  const request = await http.put(`admin/Groups/${id}`, data, { headers: headers() });
  return request;
};
export const getParticularRole = async (roleId) => {
  const request = await http.get(`admin/UserRole/${roleId}`, { headers: headers() });
  return request;
};

export const updateUserRole = async (data, roleId) => {
  const request = await http.put(`admin/UserRole/${roleId}`, data, { headers: headers() });
  return request;
};

export const orgList = async (offset, limit) => {
  const request = await http.get(
    `/admin/Organizations/list/${offset}/${limit}`, { headers: headers() }
  );
  return request;
};

export const UserList = async (offset, limit) => {
  const request = await http.get(`/admin/users/list/${offset}/${limit}`, { headers: headers() });
  return request;
}

export const roleDropDownList = async () => {
  const request = await http.get(
    `/admin/UserRole?filter[where][status]=A`, { headers: headers() }
  );
  return request;
};

export const businessDropDownList = async () => {
  const request = await http.get(
    `/admin/Groups?filter[where][status]=A`, { headers: headers() }
  );
  return request;
};

export const createUser = async (data) => {
  const request = await http.post(`/admin/users/`, data, { headers: headers() });
  return request;
}

export const getUserById = async (id) => {
  const request = await http.get(`/admin/users/${id}`, { headers: headers() });
  return request;
}

export const updateUser = async (id, data) => {
  const request = await http.put(`/admin/users/${id}`, data, { headers: headers() });
  return request;
}
export const saveOrg = async (data) => {
  const request = await http.post(`admin/Organizations`, data, { headers: headers() });
  return request;
};

export const getParticularOrg = async (orgId) => {
  const request = await http.get(`admin/Organizations/${orgId}`, { headers: headers() });
  return request;
};

export const updateOrg = async (data, orgId) => {
  const request = await http.put(`admin/Organizations/${orgId}`, data, { headers: headers() });
  return request;
};

export const employeeById = async (id) => {
  const request = await http.get(`employee360/${id}`, { headers: headers() });
  return request;
}

export const orgDetail = async (id) => {
  const request = await http.get(`/organizationid/details?orgid=${id}`, { headers: headers() });
  return request;
}
