import http, { headers } from '../config/axiosConfig';

export const scannedChartData = async (data) => {
    const request = await http.get('trend360/data', {
        params: data,
        headers: headers()
    });
    return request;
};

export const exportData = async (data) => {
    const request = await http.get('/trend360/export', {
        params: data,
        headers: headers()
    });
    return request;
};
