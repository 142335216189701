import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { UserList } from '../../middlewares/RbacMiddleware';
import Pagination from "react-js-pagination";

class ManageUsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            totalCount: 0,
            offset: 0,
            limit: 10,
            error: null,
            isLoaded: false
        };
    }

    componentDidMount() {
        const { offset, limit } = this.state;
        this.getUserList(offset, limit);
    }

    getUserList = async (offset, limit) => {
        try {
            const { data: [{ totalCount, data }] } = await UserList(offset, limit);
            if (totalCount) {
                this.setState({
                    userList: data,
                    totalCount: totalCount,
                    isLoaded: true
                });
            }

        } catch (error) {
            this.setState({
                isLoaded: true,
                error
            });
        }
    }

    handlePageChange(pageNumber) {
        //. console.log(`active page is ${pageNumber}`);
        this.setState({ offset: pageNumber });
        // console.log(this.state.offset);
        this.getUserList(this.state.offset, this.state.limit);
    }
    render() {
        const { error, isLoaded, offset, userList } = this.state;
        return (
            <React.Fragment>
                <div className="main-contnet">
                    <div>
                        <div>
                            <div>
                                <div className="pageTilte">
                                    <h5 style={{ fontWeight: "600" }}>User</h5>
                                </div>
                                <div className="admintable">
                                    <div className="row" >
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <span className='hedingtitle'>List of System Users</span>
                                                <div className="d-flex align-items-center">
                                                    <div><input type='search' className='searchbutton form-control' placeholder='Search' /></div>
                                                    <div><Link
                                                        to={{
                                                            pathname: "/manageusers",
                                                            state: {
                                                                formEdit: false,
                                                                readonly: false
                                                            }
                                                        }}
                                                        className='adminbutton'>Add New</Link></div>
                                                </div>
                                            </div>
                                            <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                                            <div className="adminMainTable">
                                                <table >
                                                    <thead>
                                                        <tr>
                                                            <th>Clock No.</th>
                                                            <th>Name</th>
                                                            <th>Role</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id='style-7'>
                                                        {(() => {
                                                            if (error) {
                                                                // return <div>Error: {error.message}</div>;
                                                                return <span>No Data Found</span>
                                                            } else if (!isLoaded) {
                                                                return <div>Loading...</div>;
                                                            } else if (userList) {
                                                                return (
                                                                    userList.map((value, index) => {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <tr key={index}>
                                                                                    {/* <td>{offset + index + 1}</td>
                                                                        <td>{`${value.lastname} ${value.firstname}`}</td> */}
                                                                                    <td>{value.username}</td>
                                                                                    <td>{value.firstname}</td>
                                                                                    <td>{value.rolename}</td>
                                                                                    <td>{value.status === 'Y' || value.status === 'A' ? 'Active' : 'Inactive'}</td>
                                                                                    <td><div className='actionbutton'>
                                                                                        <Link
                                                                                            to={{
                                                                                                pathname: "/manageusers",
                                                                                                state: {
                                                                                                    formEdit: true,
                                                                                                    UserId: value.id,
                                                                                                    readonly: true
                                                                                                }
                                                                                            }}
                                                                                        ><img
                                                                                                src={'images/ksr/action_icon.svg'}
                                                                                                className="img-fluid"
                                                                                                alt="Ksr"
                                                                                            /></Link>
                                                                                    </div></td>
                                                                                    {/* <td className="icon_actions">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/manageusers",
                                                                                    state: {
                                                                                        formEdit: true,
                                                                                        UserId: value.id
                                                                                    }
                                                                                }}
                                                                            ><img src={"images/edit.png"} alt="Edit" /></Link>
                                                                        </td> */}
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                )
                                                            } else {
                                                                return <span>No Data Found</span>
                                                            }
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}
export default ManageUsersList;