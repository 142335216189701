import React, { Component } from 'react';
import FormMiddleware from './../middlewares/FormMiddleware';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { conformVerification } from '../middlewares/User';
import * as Toasts from '../components/Toasts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Verification extends FormMiddleware {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            verificationcode: '',
            usernameerror: '',
            verificationcodeerror: '',
        }
    }

    componentDidMount() {
        // console.log('path', this.props.location);
        const query = new URLSearchParams(this.props.location.search);
        console.log(query);
        const userID = query.get('user');
        console.log(userID);

    }

    formSubmit = async (e) => {
        e.preventDefault();

        const { username, verificationcode } = this.state;
        const data = Object.freeze({ username: username, verificationcode: verificationcode });

        let formFileds = [
            'username',
            "verificationcode",
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });

        if (isValid) {
            try {
                const result = await conformVerification(data);
                // console.log('--------------', result);
                if (!result) throw result;
                if (result.status === 200) {
                    Toasts.Success(`Verification ${result.data.status}`);
                    setTimeout(() => {
                        this.props.history.push(`/`);
                    }, 4000);

                }

            } catch (error) {
                if (error.response) {
                    const data = error.response.data.error
                    Toasts.Error(`${data.message}!`);
                }
            }
        }


    }
    render() {
        return (
            <VerificationWrapper>
                <ToastContainer />
                <div className="container-fluid col-md-12 bg-color d-flex justify-content-center align-items-center">
                    <div className='row'>
                        <div className="login-container">
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className='login-bg'></div>
                                </div>
                                <div className="col-md-6">
                                    <div className='loginform'>
                                        <img className='loginleftsideimg'
                                            src={'images/ksr/login/ksr-logo.png'}
                                            className="img-fluid"
                                            alt="Ksr"
                                        />
                                        <h4 className='login-text'>Account Verification</h4>
                                        <form onSubmit={(e) => this.formSubmit(e)}>
                                            <h6 style={{ marginTop: '22px' }}>User Name</h6>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="username"
                                                    placeholder="User Name"
                                                    name="username"
                                                    onBlur={(e) => this.handleBlur(e)}
                                                    autoComplete="off"
                                                    onChange={(e) => this.handleUserInput(e)}
                                                />
                                                {this.state.usernameerror && (
                                                    <div className="errorMsg">{this.state.usernameerror}</div>
                                                )}
                                                <span className="icon_placeholder">
                                                    {' '}
                                                    <i className="far fa-user"></i>{' '}
                                                </span>
                                            </div>
                                            <h6 style={{ marginTop: '22px' }}>Verification Code</h6>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="verificationcode"
                                                    placeholder="Verification Code"
                                                    name="verificationcode"
                                                    autoComplete="off"
                                                    onBlur={(e) => this.handleBlur(e)}
                                                    onChange={(e) => this.handleUserInput(e)}
                                                />
                                                {this.state.verificationcodeerror && (
                                                    <div className="errorMsg">{this.state.verificationcodeerror}</div>
                                                )}
                                                <span className="icon_placeholder">
                                                    {' '}
                                                    <i className="far fa-user"></i>{' '}
                                                </span>
                                            </div>
                                            <input
                                                type="submit"
                                                id="loginSubmit"
                                                value="Submit"
                                                className="btn-submit"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VerificationWrapper>
        )
    }
}

const VerificationWrapper = styled.div`
.bg-color {
  height: 100vh;
  width: 100%;
  background: #EEEEEE 0% 0% no-repeat padding-box;
}
.login-container {
  width: 800px;
  height: 500px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 18px #00000026;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.login-text {
  margin-top: 22px;
  text-transform: uppercase;
  text-align: left;
}
.forgetpassword {
  color: #ED3125;
  text-transform: capitalize;
  text-align: right;
}
.btn-submit{
  margin-left: 9px;
  margin-top: 13px;
  width: 350px;
  height: 50px;
  background: #ED3125 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: white;
  border: 0
}
.powerdby {
  margin: 0 auto;
  margin-top: 25px;
  width: 300px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 12px;
  text-align: center;
}
.powerdby img {
  padding: 0 10px;
}
.loginform {
  margin: 0 auto;
  margin-top: 25px;
}

::placeholder {
  color: red !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: red;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: red;
}
a:hover {
  color: #ED3125 !important;
  text-decoration: none !important;
}

a {
  color: #ED3125 !important;
}
`
