import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { businessList } from '../../middlewares/RbacMiddleware';
import Pagination from "react-js-pagination";

class ManageBusinessList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            businessList: [],
            totalCount: 0,
            offset: 0,
            limit: 10,
            error: null,
            isLoaded: false,
        };
    }

    componentDidMount() {
        const { offset, limit } = this.state;
        this.getBusinessList(offset, limit);
    }

    getBusinessList = async (offset, limit) => {
        try {
            const { data: [{ totalCount, data }] } = await businessList(offset, limit);
            if (totalCount) {
                this.setState({
                    businessList: data,
                    totalCount: totalCount,
                    isLoaded: true
                })
            }
        } catch (error) {
            this.setState({
                isLoaded: true,
                error
            });
        }
    }

    formatDate = (date) => {
        const newDate = new Date(date);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };
        return new Intl.DateTimeFormat('en-US', options).format(newDate);
    };

    handlePageChange(pageNumber) {
        //. console.log(`active page is ${pageNumber}`);
        this.setState({ offset: pageNumber });
        // console.log(this.state.offset);
        this.getBusinessList(this.state.offset, this.state.limit);
    }

    render() {
        const { error, isLoaded, offset, businessList } = this.state;
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="tp-nav">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" style={{ cursor: "pointer" }}>
                                    <img src={"images/fileicon.png"} />
                                </li>
                                <li className="breadcrumb-item activelink">
                                    <Link to='/admindashboard'>Admin</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Business Unit
                                </li>
                            </ol>
                        </div>
                        <div className="container-fluid mb-15" id="project-list">
                            <div className="white-box" style={{ overflow: "hidden" }}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="search-box">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Search by User Name, Group Name, Roles,..." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="pull-right">
                                            <Link
                                                to={{
                                                    pathname: "/managebusiness",
                                                    state: {
                                                        formEdit: false,
                                                    }
                                                }}
                                                className="addnew_btn"><span>+ </span>Add New</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table custom-table1 analysislisttbl">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Business Unit Name</th>
                                                <th>Description</th>
                                                <th>Company</th>
                                                <th>Created By</th>
                                                <th>Updated By</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(() => {
                                                if (error) {
                                                    // return <div>Error: {error.message}</div>;
                                                    return <span>No Data Found</span>
                                                } else if (!isLoaded) {
                                                    return <div>Loading...</div>;
                                                } else if (businessList) {
                                                    return (
                                                        businessList.map((value, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td>{offset + index + 1}</td>
                                                                        <td>{value.groupname}</td>
                                                                        <td>{value.description}</td>
                                                                        <td>{value.companyname}</td>
                                                                        <td><span className="c-lbl">{value.createdby}</span>
                                                                            <p>{value.createddate ? `on
                                                                            ${this.formatDate(value.createddate)}` : '--'}</p></td>
                                                                        <td><span className="c-lbl">{value.updatedby}</span>
                                                                            <p>{value.updateddate ? `on
                                                                            ${this.formatDate(value.updateddate)}` : '--'}</p></td>
                                                                        <td className="icon_actions">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/managebusiness",
                                                                                    state: {
                                                                                        formEdit: true,
                                                                                        businessID: value.id
                                                                                    }
                                                                                }}
                                                                            ><img src={"images/edit.png"} alt="Edit" /></Link>
                                                                        </td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    );
                                                } else {
                                                    return <span>No Data Found</span>
                                                }
                                            })()}
                                        </tbody>
                                    </table>
                                    <div className="clearfix"></div>
                                    <div className="text-center" style={{ float: 'left' }}>
                                        {businessList.length > 0 && (
                                            <Pagination
                                                activePage={this.state.offset}
                                                itemsCountPerPage={this.state.limit}
                                                totalItemsCount={this.state.totalCount}
                                                pageRangeDisplayed={5}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageBusinessList;