import React, { Component } from 'react';
import StackedBarChart from '../../components/charts/StackedBarChart';
import './Employee360.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { userFilter, scannedChartData, exportData } from '../../middlewares/Employee360Api';
import moment from 'moment';
import DownloadCsvFile from '../../components/DownloadCsvFile';
import * as Toasts from '../../components/Toasts';
import FormMiddleware from '../../middlewares/FormMiddleware';


export default class Employee360 extends FormMiddleware {

    constructor(props) {
        super(props);
        this.state = {
            startdate: new Date(),
            enddate: new Date(),
            choosedate: '',
            customdate: false,
            user: 'all',
            sacnnedChartData: [],
            UserList: [],
            exportData: [],
            tableData: [],
            tableLocation: [],
            tableDevice: [],
            tableType: [],
            tableAccess: [],
            droplocation: 'all',
            dropdevice: 'all',
            droptype: 'all',
            dropaccess: 'all',
            tableVisiterState: [],
            dropVisiterState: 'all',
            username: '',
            clockno: ''
        }
    }

    componentDidMount() {
        this.getUserList();
        this.getScannedChartData();
        this.getExportData();
    }

    getScannedChartData = async () => {
        let { user, enddate, startdate, username, clockno } = this.state;
        startdate.setHours(0, 0, 0, 0);
        enddate.setHours(23, 59, 59, 0);
        let json = {};
        if (startdate || enddate) {
            json.startdate = moment(startdate).utc().format();
            json.enddate = moment(enddate).utc().format();
        }
        user = user === 'all' ? '' : user;
        if (user) {
            json.user = user;
        }

        if (username) {
            json.name = username;
        }

        if (clockno) {
            json.clockno = clockno;
        }
        try {
            const data = await scannedChartData(Object.freeze(json));
            console.log(data.data);
            if (data.status === 200) {
                const chartData = data.data;
                let formatData = [];
                if (chartData && chartData.length) {
                    const unique = [...new Set(chartData.map(item => item.location))];
                    unique.map(itemdata => {
                        const filterData = chartData.filter(item => item.location === itemdata);
                        let finalFilter = {
                            "category": itemdata,
                            "Normal": 0,
                            "AbnormalQuestionnaire": 0,
                            "AccessFail": 0,
                            "AbnormalTemperature": 0
                        };
                        filterData.map(item => {
                            if (item.visitstatename === "Abnormal Temperature") {
                                finalFilter.AbnormalTemperature = +item.count;
                            } else if (item.visitstatename === 'Normal') {
                                finalFilter.Normal = +item.count;
                            } else if (item.visitstatename === 'Access Fail') {
                                finalFilter.AccessFail = +item.count;
                            } else if (item.visitstatename === 'Abnormal Questionnaire') {
                                finalFilter.AbnormalQuestionnaire = +item.count;
                            }
                        });
                        formatData.push(finalFilter);
                    });

                }
                this.setState({
                    sacnnedChartData: formatData.length ? formatData : []
                });
            }
        } catch (error) {

        }
    }

    getUserList = async () => {
        try {
            const data = await userFilter();
            if (data.status === 200) {
                let userList = [];
                if (data.data.length) {
                    userList = data.data.map(value => {
                        return {
                            id: value.visitorname,
                            username: value.visitorname
                        };
                    });
                    userList.unshift({ id: 'all', username: 'All' });
                }
                this.setState({
                    UserList: userList,
                });
                // console.log(data.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    changeUser = (e) => {
        // console.log(e.target.value);
        this.setState({
            user: e.target.value
        });
        // setTimeout(() => {
        //     this.getScannedChartData();
        //     this.getExportData();
        // }, 500)
    }

    changeUserName = (e) => {
        this.setState({
            username: e.target.value
        });
        // setTimeout(() => {
        //     this.getScannedChartData();
        //     this.getExportData();
        // }, 500)
    }

    changeClockNo = (e) => {
        this.setState({
            clockno: e.target.value
        });
        // setTimeout(() => {
        //     this.getScannedChartData();
        //     this.getExportData();
        // }, 500)
    }

    fromDateChange = date => {
        // console.log(date);
        this.setState({
            startdate: date
        });
        // setTimeout(() => {
        //     this.getScannedChartData();
        //     this.getExportData();
        // }, 500)
    };

    toDataChange = date => {

        this.setState({
            enddate: date
        });
        // setTimeout(() => {
        //     this.getScannedChartData();
        //     this.getExportData();
        // }, 500)
    }

    chooseDateDropDown = e => {
        console.log(e.target.value);
        this.setState({
            choosedate: e.target.value
        })

        if (e.target.value === 'customdate') {
            this.setState({
                customdate: true
            });
        } else {
            this.setState({
                customdate: false,
                startdate: new Date(),
                enddate: new Date()
            });
            setTimeout(() => {
                this.getScannedChartData();
            }, 500)
        }
    }

    getExportData = async () => {
        this.setState({
            droplocation: 'all',
            dropdevice: 'all',
            droptype: 'all',
            dropaccess: 'all',
            dropVisiterState: 'all'
        });
        let { user, enddate, startdate, username, clockno } = this.state;
        startdate.setHours(0, 0, 0, 0);
        enddate.setHours(23, 59, 59, 0);
        let json = {};
        if (startdate || enddate) {
            json.startdate = moment(startdate).utc().format();
            json.enddate = moment(enddate).utc().format();
        }
        user = user === 'all' ? '' : user;
        if (user) {
            json.user = user;
        }

        if (username) {
            json.name = username;
        }

        if (clockno) {
            json.clockno = clockno;
        }
        try {
            const data = await exportData(Object.freeze(json));
            // console.log('export==>', data);
            if (data.status === 200 && data.data.length > 0) {
                console.log('inside export');
                const Device = [...new Set(data.data.map(item => item.devicename))];
                const Location = [...new Set(data.data.map(item => item.locationname))];
                const Type = [...new Set(data.data.map(item => item.visitortype))];
                const Access = [...new Set(data.data.map(item => item.access))];
                const VisiterSate = [...new Set(data.data.map(item => item.visitstatename))];
                data.data.sort(function (a, b) {
                    return new Date(b.createtimewithtime) - new Date(a.createtimewithtime);
                });
                const tableVisiterState = VisiterSate.map(value => {
                    return {
                        id: value,
                        visiterstatename: value
                    };
                });
                tableVisiterState.unshift({ id: 'all', visiterstatename: 'Visitor State' });
                const tableDevice = Device.map(value => {
                    return {
                        id: value,
                        devicename: value
                    };
                });
                tableDevice.unshift({ id: 'all', devicename: 'Device' });
                const tableLocation = Location.map(value => {
                    return {
                        id: value,
                        locationname: value
                    };
                });
                tableLocation.unshift({ id: 'all', locationname: 'Location' });
                const tableType = Type.map(value => {
                    return {
                        id: value,
                        typename: value
                    };
                });
                tableType.unshift({ id: 'all', typename: 'Type' });
                const tableAccess = Access.map(value => {
                    return {
                        id: value,
                        accessname: value
                    };
                });
                tableAccess.unshift({ id: 'all', accessname: 'Access' });

                this.setState({
                    exportData: data.data,
                    tableData: data.data,
                    tableDevice: tableDevice,
                    tableLocation: tableLocation,
                    tableType: tableType,
                    tableAccess: tableAccess,
                    tableVisiterState: tableVisiterState
                })
            } else {
                this.setState({
                    exportData: [],
                    tableData: [],
                    tableDevice: [],
                    tableLocation: [],
                    tableType: [],
                    tableAccess: [],
                    tableVisiterState: []
                })
            }
        } catch (error) {

        }
    }

    getCsvFile = async () => {
        const { exportData } = this.state;
        if (exportData.length > 0) {
            const keys = Object.keys(exportData[0]);
            let headers = {}
            keys.map(item => {
                headers[item] = item.replace(/,/g, '')
            });
            const itemsNotFormatted = exportData;

            const itemsFormatted = [];
            itemsNotFormatted.forEach((item) => {
                itemsFormatted.push({
                    visitstatename: item.visitstatename ? item.visitstatename.replace(/,/g, '') : '',
                    access: item.access ? item.access.replace(/,/g, '') : '',
                    clockno: item.clockno ? item.clockno.replace(/,/g, '') : '',
                    createtimewithtime: item.createtimewithtime ? this.formatDate(item.createtimewithtime.replace(/,/g, '')) : '',
                    devicename: item.devicename ? item.devicename.replace(/,/g, '') : '',
                    locationname: item.locationname ? item.locationname.replace(/,/g, '') : '',
                    name: item.name ? item.name.replace(/,/g, '') : '',
                    temperature: item.temperature ? item.temperature.replace(/,/g, '') : '',
                    visitortype: item.visitortype ? item.visitortype.replace(/,/g, '') : ''
                });
            });

            var fileTitle = 'staff';
            await DownloadCsvFile(headers, itemsFormatted, fileTitle);
        } else {
            Toasts.Info('Record Not Found!');
        }
    }

    exportTable = async () => {
        const { tableData } = this.state;

        if (tableData.length > 0) {
            const keys = Object.keys(tableData[0]);
            let headers = {}
            keys.map(item => {
                headers[item] = item.replace(/,/g, '')
            });
            const itemsNotFormatted = tableData;
            const itemsFormatted = [];
            itemsNotFormatted.forEach((item) => {
                itemsFormatted.push({
                    visitstatename: item.visitstatename ? item.visitstatename.replace(/,/g, '') : '',
                    access: item.access ? item.access.replace(/,/g, '') : '',
                    clockno: item.clockno ? item.clockno.replace(/,/g, '') : '',
                    createtimewithtime: item.createtimewithtime ? this.formatDate(item.createtimewithtime.replace(/,/g, '')) : '',
                    devicename: item.devicename ? item.devicename.replace(/,/g, '') : '',
                    locationname: item.locationname ? item.locationname.replace(/,/g, '') : '',
                    name: item.name ? item.name.replace(/,/g, '') : '',
                    temperature: item.temperature ? item.temperature.replace(/,/g, '') : '',
                    visitortype: item.visitortype ? item.visitortype.replace(/,/g, '') : ''
                });
            });

            var fileTitle = 'StaffTable'; // or 'my-unique-title'
            await DownloadCsvFile(headers, itemsFormatted, fileTitle);
        } else {
            Toasts.Info('Record Not Found!');
        }

    }

    filterTableValue = (e, type) => {
        // console.log('value=', e.target.value);
        // console.log('type', type);
        if (e.target.value === 'all') {
            return;
        } else {
            if (type === 'location') {
                this.setState({
                    droplocation: e.target.value,
                });
            } else if (type === 'device') {
                this.setState({
                    dropdevice: e.target.value,
                });
            } else if (type === 'type') {
                this.setState({
                    droptype: e.target.value,
                });
            } else if (type === 'access') {
                this.setState({
                    dropaccess: e.target.value,
                });
            } else if (type === 'visiterstate') {
                this.setState({
                    dropVisiterState: e.target.value
                });
                // filterdata = exportData.filter(item => item.visitstatename === e.target.value);
            }
            setTimeout(() => {
                this.setFilterData();
            }, 500);
        }

    }

    setFilterData = () => {
        const { exportData, droplocation, dropdevice, droptype, dropaccess, dropVisiterState } = this.state;
        let Obj = {};

        if (droplocation && droplocation !== 'all') {
            Obj.locationname = droplocation;
        }

        if (dropdevice && dropdevice !== 'all') {
            Obj.devicename = dropdevice;
        }

        if (droptype && droptype !== 'all') {
            Obj.visitortype = droptype;
        }

        if (dropaccess && dropaccess !== 'all') {
            Obj.access = dropaccess;
        }

        if (dropVisiterState && dropVisiterState !== 'all') {
            Obj.visitstatename = dropVisiterState;
        }

        // console.log('Obj=>', Obj);
        let Data = exportData;
        for (const [key, value] of Object.entries(Obj)) {
            console.log(Data);
            Data = Data.filter((item) => {
                if (item[key] === value) {
                    return item;
                }
            });
        }
        // console.log('filter=>', Data);

        this.setState({
            tableData: Data
        });
    }

    reSetTable = () => {
        const { exportData } = this.state;
        this.setState({
            droplocation: 'all',
            dropdevice: 'all',
            droptype: 'all',
            dropaccess: 'all',
            dropVisiterState: 'all'
        });
        this.setState({
            tableData: exportData
        });
    }

    getChartData = (data) => {
        console.log('employee', data);
    }

    formatDate = (date) => {
        const fomratDate = new Date(date);
        return moment(fomratDate).format('YYYY-MM-DD H:mm:ss');
    }

    submitFilter = () => {
        this.getScannedChartData();
        this.getExportData();
    }

    clearInputs = () => {
        this.setState({
            startdate: new Date(),
            enddate: new Date(),
            user: 'all',
            username: '',
            clockno: ''
        });

        setTimeout(() => {
            this.submitFilter();
        }, 200)
    }

    render() {
        const { UserList, sacnnedChartData, tableData,
            tableDevice, tableLocation, tableAccess, tableType, tableVisiterState } = this.state;
        return (
            <React.Fragment>
                <div className="main-contnet">
                    <div>
                        <div>
                            <div>
                                <div className="content">
                                    <div className="title">
                                        <h5 style={{ fontWeight: "600" }}>Staff 360</h5>
                                    </div>
                                    <span className='export-btn'>
                                        <button type='button' onClick={this.getCsvFile} style={{
                                            marginTop: '-42px',
                                            marginRight: '26px', height: '30px', width: '69px'
                                        }}>Export</button>
                                    </span>
                                    <hr className="hrline" />
                                    <div className="filter">
                                        <div className="btn-group">
                                            <div style={{ paddingLeft: "28px" }}>
                                                <label style={{ fontSize: "12px" }}>From Date</label>
                                                <br />
                                                <DatePicker
                                                    selected={this.state.startdate}
                                                    onChange={this.fromDateChange}
                                                />
                                            </div>
                                            <div style={{ paddingLeft: "9px" }}>
                                                <label style={{ fontSize: "12px" }}>To Date</label>
                                                <br />
                                                <DatePicker
                                                    selected={this.state.enddate}
                                                    onChange={this.toDataChange}
                                                />
                                            </div>
                                            <div style={{ paddingLeft: "9px" }}>
                                                <label style={{ fontSize: "12px" }}>User Type</label>
                                                <br />
                                                <select style={{ marginLeft: '0px' }}
                                                    value={this.state.user}
                                                    onChange={(e) => this.changeUser(e)}
                                                >
                                                    {UserList &&
                                                        UserList.length &&
                                                        UserList.map((value, index) => {
                                                            return (
                                                                <option key={index} value={value.id}>
                                                                    {value.username}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                            <div style={{ paddingLeft: "1px" }}>
                                                <label style={{ fontSize: "12px" }}>Clock No</label>
                                                <br />
                                                <input
                                                    className="form-control"
                                                    name='clockno'
                                                    id='clockno'
                                                    placeholder='Clock No'
                                                    value={this.state.clockno}
                                                    onChange={(e) => this.changeClockNo(e)}
                                                />
                                            </div>
                                            <div style={{ paddingLeft: "9px" }}>
                                                <label style={{ fontSize: "12px" }}>User Name</label>
                                                <br />
                                                <input
                                                    className="form-control"
                                                    name='username'
                                                    id='username'
                                                    placeholder='User Name'
                                                    value={this.state.username}
                                                    onChange={(e) => this.changeUserName(e)}
                                                />
                                            </div>
                                        </div>
                                        <span className='export-btn'>
                                            <button type='button' style={{ width: '57px', marginRight: '5px', marginTop: '40px' }} onClick={this.clearInputs}>Clear</button>
                                        </span>
                                        <span className='export-btn'>
                                            <button type='button' style={{ width: '57px', marginRight: '7px', marginTop: '40px', marginLeft: '12px' }} onClick={this.submitFilter}>Apply</button>
                                        </span>
                                    </div>
                                </div>
                                {/* <div className='row' style={{ padding: "0 30px" }}>
                                    <div className='col'>
                                        <div className='tiles'>
                                            <div class="cardtitle">Avg. Clock Hour <span>7.54</span></div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='tiles'>
                                            <div class="cardtitle">Salary Staff <span>8.75</span></div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='tiles'>
                                            <div class="cardtitle">Hourly Staff <span>8.55</span></div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="passesAlign">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <span className='hedingtitle'>Scanned</span>
                                                {/* <div className="d-flex align-items-center">
                                                    <div className='buttonfilter'>i</div>
                                                </div> */}
                                            </div>
                                            <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                                            <div className="passesAppliedMain">
                                                <p style={{ color: "white" }}>{sacnnedChartData.length > 0 ?
                                                    <StackedBarChart data={sacnnedChartData} chartid={'employee'}
                                                        chartclick={false} chartonClick={this.getChartData} /> :
                                                    <div style={{ marginLeft: '407px', color: '#fff', marginTop: '68px', marginBottom: '40px' }}>No Data Found</div>}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="passesAlign">
                                    <div className="row" >
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between">
                                                <span className='hedingtitle'>Scanned Records {'(' + tableData.length + ')'}</span>
                                                <div className="d-flex align-items-center">
                                                    <div className='buttonfilter' style={{ backgroundColor: '#ed3125' }} onClick={this.exportTable}>Export</div>
                                                    <div className='buttonfilter' onClick={this.reSetTable}>Reset</div>
                                                </div>
                                            </div>
                                            <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                                            {tableData.length > 0 ?
                                                <div className="adminMainTable">
                                                    <table >
                                                        <thead>
                                                            <tr>
                                                                <th>Clock No.</th>
                                                                <th>Name</th>
                                                                <th>Date & Time</th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.droplocation}
                                                                    onChange={(e) => this.filterTableValue(e, 'location')}
                                                                >
                                                                    {tableLocation &&
                                                                        tableLocation.length &&
                                                                        tableLocation.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.locationname}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.dropdevice}
                                                                    onChange={(e) => this.filterTableValue(e, 'device')}
                                                                >
                                                                    {tableDevice &&
                                                                        tableDevice.length &&
                                                                        tableDevice.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.devicename}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.droptype}
                                                                    onChange={(e) => this.filterTableValue(e, 'type')}
                                                                >
                                                                    {tableType &&
                                                                        tableType.length &&
                                                                        tableType.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.typename}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.dropaccess}
                                                                    onChange={(e) => this.filterTableValue(e, 'access')}
                                                                >
                                                                    {tableAccess &&
                                                                        tableAccess.length &&
                                                                        tableAccess.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.accessname}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th><select className='tabledropdaown'
                                                                    value={this.state.dropVisiterState}
                                                                    onChange={(e) => this.filterTableValue(e, 'visiterstate')}
                                                                >
                                                                    {tableVisiterState &&
                                                                        tableVisiterState.length &&
                                                                        tableVisiterState.map((value, index) => {
                                                                            return (
                                                                                <option key={index} value={value.id}>
                                                                                    {value.visiterstatename}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select></th>
                                                                <th>Temp</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id='style-7'>
                                                            {tableData.map((value, index) => {
                                                                return (
                                                                    <React.Fragment>
                                                                        <tr key={index}>
                                                                            <td>{value.clockno}</td>
                                                                            <td>{value.name}</td>
                                                                            <td>{this.formatDate(value.createtimewithtime)}</td>
                                                                            <td>{value.locationname}</td>
                                                                            <td>{value.devicename}</td>
                                                                            <td>{value.visitortype}</td>
                                                                            <td>{value.access}</td>
                                                                            <td>{value.visitstatename}</td>
                                                                            <td>{value.temperature}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : <div style={{ marginLeft: '436px', color: '#fff', marginTop: '68px', marginBottom: '40px' }}>No Data Found</div>}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
