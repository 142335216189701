import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
let chartContainer;

class ColumnWithLineChart extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const resultset = this.props.resultSet;
        // console.log(this.props);
        const data = [{
            'date': '2009',
            'Total case': 23.5,
            'Death': 21.1
        }, {
            'date': '2010',
            'Total case': 26.2,
            'Death': 30.5
        }, {
            'date': '2011',
            'Total case': 30.1,
            'Death': 34.9
        }, {
            'date': '2012',
            'Total case': 29.5,
            'Death': 31.1
        }, {
            'date': '2013',
            'Total case': 30.6,
            'Death': 28.2
        }, {
            'date': '2014',
            'Total case': 34.1,
            'Death': 32.9
        }];
        this.drawChart(resultset);
    }

    drawChart = (data) => {
        const axisleftlabel = this.props.axisleftTitle;
        const axisrightlabel = this.props.axisrightTitle;
        const catagory = this.props.catagory;
        const coumnChartName = this.props.coumnChartName;
        const lineChartname = this.props.lineChartname;
        let chart = am4core.create(this.props.chartid, am4charts.XYChart);

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = catagory;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis1.title.text = axisleftlabel;
        // valueAxis1.numberFormatter = new am4core.NumberFormatter();
        // valueAxis1.numberFormatter.numberFormat = '#.0a';

        const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.title.text = axisrightlabel;
        valueAxis2.renderer.opposite = true;
        valueAxis2.renderer.grid.template.disabled = true;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        let columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.name = coumnChartName;
        columnSeries.dataFields.valueY = coumnChartName;
        columnSeries.dataFields.categoryX = catagory;
        columnSeries.yAxis = valueAxis1;

        columnSeries.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
        columnSeries.columns.template.propertyFields.stroke = "stroke";
        columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
        columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
        columnSeries.tooltip.label.textAlign = "middle";

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = lineChartname;
        lineSeries.dataFields.valueY = lineChartname;
        lineSeries.dataFields.categoryX = catagory;
        lineSeries.yAxis = valueAxis2;

        lineSeries.stroke = am4core.color("#fdd400");
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.strokeDasharray = "lineDash";
        lineSeries.tooltip.label.textAlign = "middle";

        let bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
        bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";

        let circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 3;

        chart.legend = new am4charts.Legend();

        // const markerTemplate = chart.legend.markers.template;
        // markerTemplate.width = 10;
        // markerTemplate.height = 10;
        // const legendContainer = am4core.create('legend', am4core.Container);
        // legendContainer.width = am4core.percent(100);
        // chart.legend.parent = legendContainer;
        // const legendDiv = document.getElementById('l-0');

        // chart.numberFormatter.numberFormat = '#.0a';
        chart.data = data;
        this.chartContainer = chart;

    }
    render() {
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper" className="customervalue" style={{ marginTop: '0px; top: 10px' }}>
                        <label><b>{this.props.title}</b></label>
                        <div id={`${this.props.chartid}`} style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ColumnWithLineChart;