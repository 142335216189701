import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { orgList } from './../../middlewares/RbacMiddleware';
import Pagination from "react-js-pagination";

// admin/Organizations/list/0/10
class ManageOrganizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgList: [],
      totalCount: 0,
      offset: 0,
      limit: 10,
    };
  }

  componentDidMount() {
    const { offset, limit } = this.state;
    this.getOrgList(offset, limit);
  }

  getOrgList = async (offset, limit) => {
    try {
      const {
        data: [{ totalCount, data }],
      } = await orgList(offset, limit);
      if (totalCount) {
        this.setState({
          orgList: data,
          totalCount: totalCount,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  formatDate = (date) => {
    const newDate = new Date(date);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(newDate);
  };

  handlePageChange(pageNumber) {
    //. console.log(`active page is ${pageNumber}`);
    this.setState({ offset: pageNumber });
    // console.log(this.state.offset);
    this.getOrgList(this.state.offset, this.state.limit);
  }
  render() {
    const { orgList, totalCount, offset } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper">
          <div id="content-wrapper">
            <div className="tp-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item" style={{ cursor: 'pointer' }}>
                  <img src={'images/fileicon.png'} />
                </li>
                <li className="breadcrumb-item activelink">
                  <Link to="/admindashboard">Admin</Link>
                </li>
                <li className="breadcrumb-item active">ORGANIZATION</li>
              </ol>
            </div>
            <div className="container-fluid mb-15" id="project-list">
              <div className="white-box" style={{ overflow: 'hidden' }}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="search-box">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by User Name, Group Name, Roles,..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="pull-right">
                      <Link to="/manageorganization" className="addnew_btn">
                        <span>+ </span>Add New
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table custom-table1 analysislisttbl">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Company Name</th>
                        <th>Type</th>
                        <th>Location</th>
                        <th style={{ width: '150px' }}>Address</th>
                        <th>Created By</th>
                        <th>Updated By</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orgList && orgList.length ? (
                        orgList.map((value, index) => {
                          return (
                            <React.Fragment>
                              <tr>
                                <td>{offset + index + 1}</td>
                                <td>{value.companyname}</td>
                                <td>{value.companytype}</td>
                                <td>{value.state}</td>
                                <td>{value.address1}</td>
                                <td>
                                  <span className="c-lbl">
                                    {value.createdby}
                                  </span>
                                  <p>
                                    {value.createddate
                                      ? `on
                                    ${this.formatDate(value.createddate)}`
                                      : '--'}
                                  </p>
                                </td>
                                <td>
                                  <span className="c-lbl">
                                    {value.updatedby}
                                  </span>
                                  <p>
                                    {value.updateddate
                                      ? `on
                                    ${this.formatDate(value.updateddate)}`
                                      : '--'}
                                  </p>
                                </td>
                                <td className="icon_actions">
                                  <span>
                                    <Link
                                      to={`/manageorganization/${value.id}`}
                                    >
                                      <img src={'images/edit.png'} alt="Edit" />
                                    </Link>
                                  </span>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })
                      ) : (
                          <span>No Data Found</span>
                        )}
                    </tbody>
                  </table>
                  <div className="clearfix"></div>
                  <div className="text-center" style={{ float: 'left' }}>
                    {orgList.length > 0 && (
                      <Pagination
                        activePage={this.state.offset}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalCount}
                        pageRangeDisplayed={5}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={this.handlePageChange.bind(this)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ManageOrganizationList;
