import React, { Component } from 'react'
import FormMiddleware from './../middlewares/FormMiddleware';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { changePassword } from '../middlewares/User';
import * as Toasts from '../components/Toasts';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default class ChangePassword extends FormMiddleware {

  constructor(props) {
    super(props);
    this.state = {
      oldpassword: '',
      password: '',
      confpassword: '',
      passwordConfirmationError: '',
      currentpasswordError: '',
      confirmPasswordValidate: false,
    }
  }

  formSubmit = async (e) => {
    e.preventDefault();
    const { confpassword, oldpassword } = this.state;
    const username = localStorage.getItem('username');
    let data = '';
    if (username) {
      data = Object.freeze({ username: username, oldpassword: oldpassword, newpassword: confpassword });
    }
    let formFileds = [
      'oldpassword',
      "confpassword",
    ];
    let isValid = true;
    formFileds.forEach(field => {
      isValid = this.validateField(field) && isValid;
    });

    if (isValid && data) {
      try {
        const result = await changePassword(data);
        if (!result) throw result;
        // console.log('=====change====', result);
        if (result.status === 200) {
          Toasts.Success(`${result.data.message}`);
          setTimeout(() => {
            this.props.history.push(`/`);
          }, 4000);

        }
      } catch (error) {
        if (error.response) {
          const data = error.response.data.error
          Toasts.Error(`${data.message}!`);
        }
      }
    } else {
      return;
    }
  }

  render() {
    return (
      <ChangePasswordWrapper>
        <ToastContainer />
        <div className="container-fluid col-md-12 bg-color d-flex justify-content-center align-items-center">
          <div className='row'>
            <div className="login-container">
              <div className='row'>
                <div class="col-md-6">
                  <div className='forgotpassword-bg'></div>
                </div>
                <div class="col-md-6">
                  <div className='loginform'>
                    <img className='loginleftsideimg'
                      src={'images/ksr/login/ksr-logo.png'}
                      className="img-fluid"
                      alt="Ksr"
                    />
                    <h4 className='login-text'>Change Password</h4>
                    <form onSubmit={(e) => this.formSubmit(e)}>
                      <h6 style={{ marginTop: '22px' }}>Current Password</h6>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="oldpassword"
                          placeholder="Current Password"
                          name="oldpassword"
                          onBlur={(e) => this.handleBlur(e)}
                          autoComplete="off"
                          onChange={(e) => this.handleUserInput(e)}
                        />
                        {this.state.currentpasswordError && (
                          <div className="errorMsg">{this.state.currentpasswordError}</div>
                        )}
                      </div>
                      <h6 style={{ marginTop: '22px' }}>New Password</h6>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          placeholder="New Password"
                          name="password"
                          autoComplete="off"
                          onChange={(e) => this.passwordValidate(e)}
                        />
                      </div>
                      <div id="message" style={{ display: 'none' }}>
                        <div className="triangle-up"></div>
                        <p id="letter" className="invalid">
                          Password must have One Lowercase
                                                        </p>
                        <p id="capital" className="invalid">
                          Password must have One Uppercase
                                                        </p>
                        <p id="number" className="invalid">
                          Password must have One Number
                                                        </p>
                        <p id="specialChareacter" className="invalid">
                          Password must have One Special Character
                                                        </p>
                        <p id="length" className="invalid">
                          Password should have Minimum 8 Character
                                                        </p>
                      </div>
                      <h6 style={{ marginTop: '22px' }}>Confirm  Password</h6>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="confpassword"
                          name="confpassword"
                          placeholder="Confirm Password"
                          autoComplete="off"
                          onBlur={(e) => this.handleBlur(e)}
                          onChange={(e) => this.confirmPassword(e)}
                        />
                        {this.state.passwordConfirmationError && (
                          <div className="errorMsg">{this.state.passwordConfirmationError}</div>
                        )}
                      </div>
                      <input
                        type="submit"
                        id="loginSubmit"
                        value="Submit"
                        className="btn-submit"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ChangePasswordWrapper>
    )
  }
}

const ChangePasswordWrapper = styled.div`
.bg-color {
  height: 100vh;
  width: 100%;
  background: #EEEEEE 0% 0% no-repeat padding-box;
}
.login-container {
  width: 800px;
  min-height: 545px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 18px #00000026;
  border-radius: 8px;
  opacity: 1;
  padding: 10px;
}
.login-text {
  margin-top: 22px;
  text-transform: uppercase;
  text-align: left;
}
.forgetpassword {
  color: #ED3125;
  text-transform: capitalize;
  text-align: right;
}
.btn-submit{
  margin-left: 9px;
  margin-top: 13px;
  width: 350px;
  height: 50px;
  background: #ED3125 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  color: white;
  border: 0
}
.powerdby {
  margin: 0 auto;
  margin-top: 25px;
  width: 300px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 12px;
  text-align: center;
}
.powerdby img {
  padding: 0 10px;
}
.loginform {
  margin: 0 auto;
  margin-top: 25px;
}

::placeholder {
  color: red !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: red;
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: red;
}
a:hover {
  color: #ED3125 !important;
  text-decoration: none !important;
}

a {
  color: #ED3125 !important;
}
`
