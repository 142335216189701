import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AdminDashboard extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="tp-nav">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" style={{ cursor: "pointer" }}>
                                    <img src={"images/fileicon.png"} />
                                </li>
                                <li className="breadcrumb-item active">
                                    Admin
                                </li>
                            </ol>
                        </div>
                        <div className="container-fluid mb-15" id="project-list" style={{ textAlign: "center" }}>
                            <Link to='/manageorganizationList'>
                                <div className="service-block-v3 service-block">
                                    <div className="service-block-content">
                                        <div className="service-block-icon organisation_manageicon">
                                            <img src={"images/organisation-manage.png"} />
                                        </div>
                                        <h4 className="service-block-title">Organization</h4>
                                        <p className="service-block-text"> Add, Update & Manage Organization</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/managebusinessList'>
                                <div className="service-block-v3 service-block">
                                    <div className="service-block-content">
                                        <div className="service-block-icon group_manageicon">
                                            <img src={"images/group-manage.png"} />
                                        </div>
                                        <h4 className="service-block-title">Business Unit</h4>
                                        <p className="service-block-text">Add, Update & Manage Business Units</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/managerolesList'>
                                <div className="service-block-v3 service-block">
                                    <div className="service-block-content">
                                        <div className="service-block-icon role_manageicon">
                                            <img src={"images/role-manage.png"} />
                                        </div>
                                        <h4 className="service-block-title">Roles</h4>
                                        <p className="service-block-text">Add, Update & Manage Roles</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/manageuserList'>
                                <div className="service-block-v3 service-block">
                                    <div className="service-block-content">
                                        <div className="service-block-icon man_usericon">
                                            <img src={"images/man-user.png"} />
                                        </div>
                                        <h4 className="service-block-title">Users</h4>
                                        <p className="service-block-text">Add, Update & Manage Users</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AdminDashboard;