import http, { headers } from '../config/axiosConfig';

export const userFilter = async () => {
    const request = await http.get('employee360/view_users', {
        headers: headers()
    });
    return request;
};

export const scannedChartData = async (data) => {
    const request = await http.get('employee360/data', {
        params: data,
        headers: headers()
    });
    return request;
};

export const exportData = async (data) => {
    const request = await http.get('/employee360/export', {
        params: data,
        headers: headers()
    });
    return request;
};