import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import RefreshToken from './../middlewares/RefreshToken';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { localstorage } from '../config/axiosConfig';

function Header(props) {
  let location = useLocation();

  let Location360ActiveClass = '';
  let EmployeeActiveClass = '';
  let AdminActiveClass = '';
  let Trend360ActiveClass = '';
  let LocationActive = false;
  let EmployeeAcive = false;
  let AdminActive = false;
  let TrendActive = false;

  if (location.pathname === '/location360') {
    Location360ActiveClass = 'active';
    LocationActive = true;
  } else if (location.pathname === '/employee360') {
    EmployeeActiveClass = 'active';
    EmployeeAcive = true;
  } else if (location.pathname === '/manageuserList' || location.pathname === '/manageusers') {
    AdminActiveClass = 'active';
    AdminActive = true;
  } else if (location.pathname === '/trend360' || location.pathname === '/trend360') {
    Trend360ActiveClass = 'active';
    TrendActive = true;
  }


  // console.log('heasderssssssssssssss', location);

  const userDetails = localstorage();
  return (
    <React.Fragment>
      <RefreshToken />
      <ToastContainer />
      <NavWraper>
        <nav className="navbar navbar-light bg-color justify-content-between">
          <a className="navbar-brand">
            <img src={'/images/ksr/header/ksr-logo.png'} className="img-fluid" alt="logo" />
          </a>
          <div className="form-inline">
            {/* <img src="/images/ksr/header/questionmark.svg" alt="bell" />
            <img src="/images/ksr/header/bell.svg" alt="bell" /> */}

            <div className="dropdown">
              <div className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img src={'/images/ksr/header/human.png'} alt="user" className='profile' />
              </div>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <div style={{ marginLeft: '89px' }}><Link to='/'>Log out</Link></div>
              </div>

            </div>
          </div>
        </nav>
      </NavWraper>
      <Sidebar>
        <div className="sidebar">
          <ul className='leftmenu'>
            <Link to='/location360'><li className={`${Location360ActiveClass}`}><span style={{ color: '#fff', marginRight: '13px' }}
            ><img src={`/images/ksr/header/${LocationActive ? 'location_360_active.svg' : 'location_360.svg'}`} alt="location" /></span>Location 360</li></Link>
            <Link to='/employee360'><li className={`${EmployeeActiveClass}`}><span style={{ color: '#fff', marginRight: '13px' }}>
              <img src={`/images/ksr/header/${EmployeeAcive ? 'Employe2e_360_active.svg' : 'Employee_360.svg'}`} alt="employee" /></span>Staff 360</li></Link>
            <Link to='/trend360'><li className={`${Trend360ActiveClass}`}><span style={{ color: '#fff', marginRight: '13px' }}>
              <img src={`/images/ksr/header/${TrendActive ? 'trend_350_active.svg' : 'trend_350.svg'}`} alt="employee" /></span>Trend 360</li></Link>
            <Link to='/manageuserList'><li className={`${AdminActiveClass}`}><span style={{ color: '#fff', marginRight: '13px' }}>
              <img src={`/images/ksr/header/${AdminActive ? 'admin_active.svg' : 'admin.svg'}`} alt="admin" /></span>Admin</li></Link>
          </ul>
        </div>
      </Sidebar>
    </React.Fragment>
  );
}

const NavWraper = styled.nav`
.bg-color {
  position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 12px #00000029;
}
.logo{
  padding: 0;
}
.navbar {
  padding: 0px;
}
.navbar-brand {
  background: #FFF;
  padding: 9px;
  width: 245px;
}
.form-inline {
  padding-right: 25px;
}
.form-inline img{
    margin: 0 5px;
    width: 35px;
    height: 35px;
    padding: 0 10px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border-radius: 50%;
}
.dropdown-menu {
  background: transparent;
  border: 0;
}
.dropdown-menu-right a {
  color: #FFF !important;
  text-decoration: none !important;
}
.dropdown-menu-right a:hover {
  color: #fff!important;
  text-decoration: none !important;
} 
`
const Sidebar = styled.div`
.sidebar {
  position: fixed;
  z-index: 1;
  height: calc(100vh - 60px);
  padding: 25px;
  float: left;
  width: 245px;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 15px #00000029;
}
.leftmenu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.leftmenu li{
  margin-top: 18px;
  padding: 5px 20px;
  color: #ED3125;
  line-height: 30px;
  text-transform: uppercase;
}
.leftmenu li.active {
  background: #ED3125;
  color: #FFFFFF;
  border-radius: 8px;
}
`


export default Header;
