import React from 'react'

const DownloadCsvFile = async (headers, itemsFormatted, fileTitle) => {
    // console.log('--------------');
    // console.log(headers, itemsFormatted, fileTitle);
    return await ExportCSVFile(headers, itemsFormatted, fileTitle);
}

const ExportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
        items.unshift(headers);
    }
    // Convert Object to JSON
    const jsonObject = JSON.stringify(items);

    const csv = ConvertToCSV(jsonObject);

    const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    // console.log('fineal csv==>', csv);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

const ConvertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(array[0])
    // console.log(header);
    const csv = [
        ...array.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')
    // console.log(csv);
    return csv;
}

export default DownloadCsvFile;