import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class ClusteredBarChart extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const resultset = this.props.resultSet;
        this.drawChart(resultset);
    }

    drawChart = (data) => {
        const catagory = this.props.catagory;
        const bar1 = this.props.bar1;
        const bar2 = this.props.bar2;
        let chart = am4core.create(this.props.chartid, am4charts.XYChart);
        chart.data = data;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = catagory
        // categoryAxis.numberFormatter.numberFormat = "#";
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.opposite = true;

        this.createSeries(bar1, bar1, catagory, chart);
        this.createSeries(bar2, bar2, catagory, chart);
        chart.legend = new am4charts.Legend();
    }

    createSeries = (field, name, catagory, chart) => {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = field;
        series.dataFields.categoryY = catagory;
        series.name = name;
        series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = true;

        // let valueLabel = series.bullets.push(new am4charts.LabelBullet());
        // valueLabel.label.text = "{valueX}";
        // valueLabel.label.horizontalCenter = "left";
        // valueLabel.label.dx = 10;
        // valueLabel.label.hideOversized = false;
        // valueLabel.label.truncate = false;

        // let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
        // categoryLabel.label.text = "{name}";
        // categoryLabel.label.horizontalCenter = "right";
        // categoryLabel.label.dx = -10;
        // categoryLabel.label.fill = am4core.color("#fff");
        // categoryLabel.label.hideOversized = false;
        // categoryLabel.label.truncate = false;
    }
    render() {
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper" className="customervalue" style={{ width: 600 }}>
                        <label><b>{this.props.title}</b></label>
                        <div id={`${this.props.chartid}`} style={{ width: "100%", height: "500px" }}></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ClusteredBarChart;