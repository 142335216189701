import React from 'react';
import { Link } from "react-router-dom";
import { roleOrganization, addBusiness, getBusinnesById, updateBuisnessUnit } from '../../middlewares/RbacMiddleware';
import FormMiddleware from '../../middlewares/FormMiddleware';

const userDetail = JSON.parse(localStorage.getItem('userDetails'));

class ManageBusiness extends FormMiddleware {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            businessname: '',
            businessdescription: '',
            companyname: '',
            status: true,
            businessnameerror: '',
            businessdescriptionerror: '',
            companynameerror: '',
            createdby: '',
        };
    }

    componentDidMount() {
        this._isMounted = true;
        const params = this.props.location.state;
        if (params.formEdit) {
            // console.log(params.businessID);
            this.getBusinnessByID(params.businessID);

        }
        this.getCompanyList();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    addBusinnesUnit = async () => {
        const { businessname, businessdescription, companyname, status } = this.state;
        const Changestatus = status === true ? 'A' : 'I';
        const admin = false;
        const FormData = {
            'groupname': businessname,
            'description': businessdescription,
            'organizationid': companyname,
            'isadmin': admin,
            'status': Changestatus,
            'createdby': userDetail.displayname
        };
        // console.log(FormData);
        if (FormData) {
            try {
                const { data } = await addBusiness(FormData);
                if (data) {
                    console.log(data);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    updateBusinessUnit = async () => {
        const params = this.props.location.state;
        const { businessname, businessdescription, companyname, status, createdby } = this.state;
        const Changestatus = status === true ? 'A' : 'I';
        const admin = false;
        const FormData = {
            'groupname': businessname,
            'description': businessdescription,
            'organizationid': companyname,
            'isadmin': admin,
            'status': Changestatus,
            'updatedby': userDetail.displayname,
            'createdby': createdby ? createdby : userDetail.displayname,
        };
        // console.log(FormData);
        if (FormData) {
            try {
                const { data } = await updateBuisnessUnit(FormData, params.businessID);
                if (data) {
                    console.log(data);
                }
            } catch (error) {
                console.error(error);
            }
        }

    }

    deleteBusinessUnit = async () => {
        const params = this.props.location.state;
        const { businessname, businessdescription, companyname, createdby } = this.state;
        const Changestatus = 'D';
        const admin = false;
        const FormData = {
            'groupname': businessname,
            'description': businessdescription,
            'organizationid': companyname,
            'isadmin': admin,
            'status': Changestatus,
            'updatedby': userDetail.displayname,
            'createdby': createdby
        };
        // console.log(FormData);
        if (FormData) {
            try {
                const { data } = await updateBuisnessUnit(FormData, params.businessID);
                if (data) {
                    console.log(data);
                }
            } catch (error) {
                console.error(error);
            }
        }

    }

    getBusinnessByID = async (id) => {
        try {
            const { data } = await getBusinnesById(id);
            // console.log(data);
            if (data) {
                this.setState({
                    businessname: data.groupname,
                    businessdescription: data.description,
                    companyname: data.organizationid,
                    status: data.status === 'A' ? true : false,
                    createdby: data.createdby
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    getCompanyList = async () => {
        try {
            const { data } = await roleOrganization();
            if (!data && !data.length) return;
            //   console.log(data); companyname
            let companyName = [];
            companyName = data.map(value => {
                return {
                    id: value.id,
                    companyname: value.companyname
                };
            });
            this.setState({
                companyList: companyName,
            });
        } catch (error) {
            console.error(error);
        }
    };

    submitBusinessUnit = async (e) => {
        e.preventDefault();
        const params = this.props.location.state;
        let formFileds = [
            "businessname",
            "businessdescription",
            "companyname"
        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = this.validateField(field) && isValid;
        });
        if (isValid) {
            if (params.formEdit) {
                this.updateBusinessUnit();
            } else {
                this.addBusinnesUnit();
            }
        } else {
            return false;
        }
    }

    handleCheckBox = () => {
        this.setState({
            status: !this.state.status
        });
    };
    render() {
        const { companyList } = this.state;
        const params = this.props.location.state;
        return (
            <React.Fragment>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="tp-nav">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" style={{ cursor: "pointer" }}>
                                    <img src={"images/fileicon.png"} />
                                </li>
                                <li className="breadcrumb-item activelink">
                                    <Link to='/admindashboard'>Admin</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    ADD Business Unit
                                </li>
                            </ol>
                        </div>
                        <div className="container-fluid mb-15" id="project-list"></div>
                        <div className="white-box" style={{ overflow: "hidden" }}>
                            <div className="col-md-12">
                                <form autoComplete="off">
                                    <div className="row">
                                        <div className="col-md-6 backtoorganisationlist">
                                            <Link to='/managebusinessList'> <i className="fas fa-reply"></i><span>Back to List</span></Link>
                                        </div>
                                    </div>
                                    <div className="admin_listpage">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row form-group">
                                                        <label className="filter-lbl col-md-4">Business Unit Name<sup>*</sup></label>
                                                        <div className="col-md-7">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Business Unit Name"
                                                                name='businessname'
                                                                id='businessname'
                                                                value={this.state.businessname}
                                                                onBlur={(e) => this.handleBlur(e)}
                                                                autoComplete="off"
                                                                onChange={(e) => this.handleUserInput(e)}
                                                            />
                                                            <br />
                                                            {this.state.businessnameerror && (
                                                                <div className="errorMsg">{this.state.businessnameerror}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <label className="filter-lbl col-md-4">Description<sup>*</sup></label>
                                                        <div className="col-md-7">
                                                            <textarea
                                                                className="form-control"
                                                                rows="4" cols="50"
                                                                placeholder="Description"
                                                                name='businessdescription'
                                                                id='businessdescription'
                                                                value={this.state.businessdescription}
                                                                onBlur={(e) => this.handleBlur(e)}
                                                                autoComplete="off"
                                                                onChange={(e) => this.handleUserInput(e)}
                                                            />
                                                            <br />
                                                            {this.state.businessdescriptionerror && (
                                                                <div className="errorMsg">{this.state.businessdescriptionerror}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <label className="filter-lbl col-md-4">Company<sup>*</sup></label>
                                                        <div className="col-md-7">
                                                            <select
                                                                className="form-control"
                                                                name='companyname'
                                                                id='companyname'
                                                                onBlur={(e) => this.handleBlur(e)}
                                                                autoComplete="off"
                                                                value={this.state.companyname}
                                                                onChange={(e) => this.handleUserInput(e)}
                                                            >
                                                                <option value="" disabled selected>Select Company</option>
                                                                {companyList &&
                                                                    companyList.length &&
                                                                    companyList.map((value, index) => {
                                                                        return (
                                                                            <option key={index} value={value.id}>
                                                                                {value.companyname}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                            <br />
                                                            {this.state.companynameerror && (
                                                                <div className="errorMsg">{this.state.companynameerror}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <label className="filter-lbl col-md-4">Status</label>
                                                        <div className="col-md-7" style={{ paddingTop: "15px" }}>
                                                            <input type="checkbox"
                                                                className="custom-control-input"
                                                                id="status"
                                                                name="status"
                                                                value={this.state.status}
                                                                checked={this.state.status}
                                                                onChange={() => this.handleCheckBox()}
                                                            />
                                                            <label className="custom-control-label" htmlFor="status"></label>
                                                            <span>Active</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pull-right admin_actionsbtn">
                                        <button type="button" className="btn btn-default">Cancel</button>
                                        <button type="button" className="btn btn-default btn-save" onClick={(e) => this.submitBusinessUnit(e)}>{params.formEdit ? 'Update & Close' : 'Save'}</button>
                                        {params.formEdit && (
                                            <button type="button" className="btn btn-default btn-save" onClick={(e) => this.deleteBusinessUnit(e)}>Delete</button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageBusiness;