import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    roleOrganization, roleDropDownList,
    businessDropDownList, createUser, getUserById, updateUser, locationList, employeeById, orgDetail
} from '../../middlewares/RbacMiddleware';
import FormMiddleware from '../../middlewares/FormMiddleware';
import * as Toasts from '../../components/Toasts';
import { localstorage } from '../../config/axiosConfig'

let userDetail = '';

class ManageUsers extends FormMiddleware {

    constructor(props) {
        super(props);
        this.state = {
            companyList: [],
            roleList: [],
            LocationList: [],
            businessList: [],
            id: '',
            firstname: '',
            lastname: '',
            displayname: '',
            username: '',
            email: '',
            mobile: '+919710522789',
            organizationroleid: '867db4cc-0dac-41cf-a83f-6d686b9ab59a',
            organizationid: '8bb0ea3f-4e18-4acb-b367-c4c4d68894f8',
            organizationlocationid: '5b4cf637-1fe9-4839-bacd-1a05ad9c30d7',
            organizationgroupid: '5f401ab5-630b-4356-9825-8aab69671f51',
            createdby: '',
            profilephoto: '',
            passwrdresetattempt: 0,
            confirmed: false,
            userlogged: 'N',
            status: false,
            firstnameerror: '',
            lastnameerror: '',
            usernameerror: '',
            emailerror: '',
            phonenumbererror: '',
            companynameerror: '',
            businessnameerror: '',
            statuserror: '',
            roleiderror: '',
            formVisble: false,
            clockNoEdit: false,
            orgDetail: []
        };
    }

    componentDidMount() {
        const params = this.props.location.state;
        userDetail = localstorage();
        // console.log('local', userDetail);
        if (params && params.formEdit) {
            // console.log(params.businessID);
            this.editUser(params.UserId);
            this.setState({
                formVisble: true,
                clockNoEdit: true
            })
        }
        if (userDetail.organization) {
            this.getOrgDetail(userDetail.organization);
        }
        this.getLocationList();
        // this.getCompanyList();
        // this.getRoleList();
        // this.getBusinessList();
    }

    getOrgDetail = async (id) => {
        try {
            const data = await orgDetail(id);
            // console.log('---org---', data.data);
            if (data.data && data.data.length) {
                this.setState({
                    orgDetail: data.data
                });
            }
        } catch (error) {

        }
    }
    getCompanyList = async () => {
        try {
            const { data } = await roleOrganization();
            if (!data && !data.length) return;
            //   console.log(data); companyname
            let companyName = [];
            companyName = data.map(value => {
                return {
                    id: value.id,
                    companyname: value.companyname
                };
            });
            this.setState({
                companyList: companyName,
            });
        } catch (error) {
            console.error(error);
        }
    };

    getRoleList = async () => {
        try {
            const { data } = await roleDropDownList();
            if (!data && !data.length) return;
            //   console.log(data); companyname
            let roleList = [];
            roleList = data.map(value => {
                return {
                    id: value.id,
                    rolename: value.rolename
                };
            });
            this.setState({
                roleList: roleList,
            });
        } catch (error) {
            console.error(error);
        }
    }

    getBusinessList = async () => {
        try {
            const { data } = await businessDropDownList();
            if (!data && !data.length) return;
            //   console.log(data); companyname
            let businessList = [];
            businessList = data.map(value => {
                return {
                    id: value.id,
                    groupname: value.groupname
                };
            });
            this.setState({
                businessList: businessList,
            });
        } catch (error) {
            console.error(error);
        }
    }

    getLocationList = async () => {
        try {
            const { data } = await locationList();
            if (!data && !data.length) return;
            //   console.log(data); companyname
            let LocationList = [];
            LocationList = data.map(value => {
                return {
                    id: value.id,
                    locationname: value.locname
                };
            });
            this.setState({
                LocationList: LocationList,
            });
        } catch (error) {
            console.error(error);
        }
    }

    editUser = async (id) => {
        try {
            const { data } = await getUserById(id);
            console.log(data);
            if (data) {
                this.setState({
                    id: data.id,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    displayname: data.displayname,
                    username: data.username,
                    email: data.email,
                    mobile: data.mobile,
                    organizationroleid: data.organizationroleid,
                    organizationid: data.organizationid,
                    organizationlocationid: data.organizationlocationid,
                    organizationgroupid: data.organizationgroupid,
                    profilephoto: data.profilephoto,
                    passwrdresetattempt: data.passwrdresetattempt,
                    confirmed: data.confirmed,
                    userlogged: data.userlogged,
                    createdby: data.createdby,
                    status: data.status === 'Y' || data.status === 'A' ? true : false,
                });
            }
        } catch (error) {
            console.log(error)
        }
    }

    handleCheckBox = () => {
        this.setState({
            status: !this.state.status
        });
    };

    submitBusinessUnit = async (e) => {
        e.preventDefault();
        const params = this.props.location.state;
        // let formFileds = [
        //     "firstname",
        //     "lastname",
        //     "username",
        //     "email",
        //     "companyname",
        //     "businessname",
        //     "roleid"
        // ];
        let isValid = true;
        // formFileds.forEach(field => {
        //     isValid = this.validateField(field) && isValid;
        // });
        if (isValid) {
            if (params.formEdit) {
                this.uspateUserDetail();
            } else {
                this.addUser();
            }
        } else {
            return false;
        }
    }

    addUser = async () => {
        console.log('userdeyial', userDetail);
        const { firstname, username, email, organizationroleid, organizationlocationid,
            organizationgroupid, mobile, status, organizationid, profilephoto, confirmed,
            userlogged, passwrdresetattempt, orgDetail } = this.state;
        if (!status) {
            this.setState({
                statuserror: 'Check Active to assign role to the user!'
            });
            return
        }
        const Changestatus = status === true ? 'Y' : 'I';
        const FormData = {
            'firstname': firstname,
            'lastname': firstname,
            'displayname': firstname,
            'username': username,
            'email': email,
            'mobile': mobile,
            'organizationroleid': orgDetail[0].organizationroleid ? orgDetail[0].organizationroleid : organizationroleid,
            'organizationid': userDetail.organization ? userDetail.organization : organizationid,
            'organizationlocationid': orgDetail[0].organizationlocationid ? orgDetail[0].organizationlocationid : organizationlocationid,
            'organizationgroupid': orgDetail[0].organizationgroupid ? orgDetail[0].organizationgroupid : organizationgroupid,
            'profilephoto': profilephoto,
            'passwrdresetattempt': passwrdresetattempt,
            'confirmed': confirmed,
            'userlogged': userlogged,
            'status': Changestatus,
            'confirmed': false,
            'createdby': userDetail.id
        };
        // console.log(FormData);
        if (FormData) {
            try {
                const data = await createUser(FormData);
                if (data.status === 200) {
                    this.props.history.push(`/manageuserList`);
                }
            } catch (error) {
                if (error.response) {
                    const data = error.response.data.error
                    this.setState({
                        formVisble: false
                    })
                    Toasts.Warning(`${data.message}!`);
                }
            }
        } else {
            return;
        }
    }

    uspateUserDetail = async () => {
        const params = this.props.location.state;
        const { firstname, username, email, organizationroleid, organizationlocationid,
            organizationgroupid, mobile, status, organizationid, profilephoto, confirmed,
            userlogged, passwrdresetattempt, lastname, displayname, createdby } = this.state;
        const Changestatus = status === true ? 'Y' : 'I';
        const FormData = {
            'firstname': firstname,
            'lastname': lastname,
            'displayname': displayname,
            'username': username,
            'email': email,
            'mobile': mobile,
            'organizationroleid': organizationroleid,
            'organizationid': organizationid,
            'organizationlocationid': organizationlocationid,
            'organizationgroupid': organizationgroupid,
            'profilephoto': profilephoto,
            'passwrdresetattempt': passwrdresetattempt,
            'confirmed': confirmed,
            'userlogged': userlogged,
            'status': Changestatus,
            'confirmed': false,
            'createdby': createdby ? createdby : userDetail.id,
            'updatedby': userDetail.id
        };
        // console.log(FormData);
        if (FormData) {
            const data = await updateUser(params.UserId, FormData);
            console.log(data);
            if (data.status === 204) {
                this.props.history.push(`/manageuserList`);
            }
        } else {
            return;
        }

    }

    deleteUser = async () => {
        const params = this.props.location.state;
        const { firstname, lastname, username, email, phonenumber, companyname, businessname, roleid, status, createdby } = this.state;
        const Changestatus = 'D';
        const FormData = {
            'id': username,
            'firstname': firstname,
            'lastname': lastname,
            'displayname': `${firstname} ${lastname}`,
            'username': username,
            'email': email,
            'phonenumber': `+${phonenumber}`,
            'organizationid': companyname,
            'roleid': roleid,
            'groupid': businessname,
            'status': Changestatus,
            'confirmed': false,
            'createdby': createdby ? createdby : userDetail.displayname,
            'updatedby': userDetail.displayname,
        };
        // console.log(FormData);
        if (FormData) {
            const { data } = await updateUser(params.UserId, FormData);
            console.log(data);
        } else {
            return;
        }

    }

    handleOptionChange = (changeEvent) => {
        this.setState({
            organizationroleid: changeEvent.target.value
        });
    }

    getEmloyeeDetailById = async () => {
        const { username } = this.state;
        if (username) {
            try {
                const data = await employeeById(username);
                if (data.status === 200) {
                    if (data.data.length > 0) {
                        const user = data.data[0];
                        this.setState({
                            firstname: user.employeeName,
                            email: user.employeeEmail
                        });
                        this.setState({
                            formVisble: true
                        })
                    } else {
                        this.setState({
                            formVisble: false
                        })
                        Toasts.Info('User Not Found!');
                    }
                }
                console.log('user detail', data);
            } catch (error) {
                this.setState({
                    formVisble: false
                })
            }
        }
    }
    render() {
        const { companyList, LocationList, businessList, clockNoEdit, formVisble } = this.state;
        const params = this.props.location.state;
        // readOnly={params.readonly}
        return (
            <React.Fragment>
                <div className="main-contnet">
                    <div>
                        <div className="row" >
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <span className='hedingtitle'>Add / Edit User</span>
                                    <div className="d-flex align-items-center">
                                        <div><Link to='/manageuserList' className='adminbutton'>Back</Link></div>
                                    </div>
                                </div>
                                <hr style={{ border: "1px solid #646464", marginTop: "0" }} />
                                <div className="passesAppliedMain adminfomm">
                                    <form autoComplete="off" onSubmit={e => { e.preventDefault(); }}>
                                        <div className='row'>
                                            <div className="col-md-12">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="inputEmail4">Clock No.</label>
                                                        <input type="text" className="form-control"
                                                            name='username'
                                                            id='username'
                                                            value={this.state.username}
                                                            // onBlur={(e) => this.handleBlur(e)}
                                                            autoComplete="off"
                                                            onChange={(e) => this.handleUserInput(e)}
                                                            placeholder='Clock no'
                                                            readOnly={clockNoEdit} />
                                                    </div>
                                                    {params && !params.formEdit &&
                                                        <span><button className='adminbutton' style={{ marginTop: '31px', marginLeft: '30px' }}
                                                            type="button"
                                                            onClick={this.getEmloyeeDetailById}
                                                        >Search</button></span>
                                                    }
                                                </div>
                                                {formVisble && (
                                                    <div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputPassword4">Name</label>
                                                                <input type="text" className="form-control"
                                                                    name='firstname'
                                                                    id='firstname'
                                                                    value={this.state.firstname}
                                                                    // onBlur={(e) => this.handleBlur(e)}
                                                                    autoComplete="off"
                                                                    onChange={(e) => this.handleUserInput(e)}
                                                                    placeholder='Name'
                                                                    readOnly />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="inputPassword4">Email</label>
                                                                <input type="text" className="form-control"
                                                                    name='email'
                                                                    id='email'
                                                                    value={this.state.email}
                                                                    // onBlur={(e) => this.handleBlur(e)}
                                                                    autoComplete="off"
                                                                    onChange={(e) => this.handleUserInput(e)}
                                                                    placeholder='Email'
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputPassword4" className="roleradiobutton" style={{ marginRight: '31px', marginTop: '3px' }}>Status</label>
                                                            <div className="col-md-7" style={{ marginLeft: '73px' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="status"
                                                                    value={this.state.status}
                                                                    checked={this.state.status}
                                                                    onChange={() => this.handleCheckBox()}
                                                                />
                                                                <label htmlFor="inputPassword4">Active </label>
                                                            </div>
                                                        </div>
                                                        {this.state.statuserror && (
                                                            <div className="errorMsg" style={{ marginTop: '-18px', marginBottom: '14px' }}>{this.state.statuserror}</div>
                                                        )}
                                                        <div className="form-row">
                                                            <div></div>
                                                            <button type="button" className='btn-default'><Link to='/manageuserList'>Cancel</Link></button>
                                                            <button type="submit" className='btn-default' onClick={(e) => this.submitBusinessUnit(e)}>{params && params.formEdit ? 'Update' : 'Save'}</button>
                                                            {/* {params.formEdit && (
                                                <button type="button" className="btn btn-default btn-save" onClick={() => this.deleteUser()} > Delete</button>
                                                )} */}
                                                            {/* <button className='btn-default'>cancel</button>
                                                    <button className='btn-default'>submit</button> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default ManageUsers;