import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default class LineChart extends Component {

    componentDidMount() {
        const resultset = this.props.data;
        // console.log('chart compnent');
        this.drawChart(resultset);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('prevProps', prevProps);
        // console.log('prevState', prevState);
        const resultset = this.props.data;
        // console.log('chart compnent update', resultset);
        if (prevProps.data !== resultset) {
            // console.log('inside');
            this.drawChart(resultset);
        }
    }

    drawChart = (data) => {
        let chart = am4core.create(this.props.chartid, am4charts.XYChart);
        // console.log('chartdata=>', data);
        chart.data = data;

        // chart.data = [{
        //     "date": new Date(2018, 0, 1),
        //     "value": 450,
        //     "value2": 362,
        //     "value3": 699
        // }, {
        //     "date": new Date(2018, 0, 2),
        //     "value": 269,
        //     "value2": 450,
        //     "value3": 841
        // }, {
        //     "date": new Date(2018, 0, 3),
        //     "value": 700,
        //     "value2": 358,
        //     "value3": 699
        // }, {
        //     "date": new Date(2018, 0, 4),
        //     "value": 490,
        //     "value2": 367,
        //     "value3": 500
        // }, {
        //     "date": new Date(2018, 0, 5),
        //     "value": 500,
        //     "value2": 485,
        //     "value3": 369
        // }, {
        //     "date": new Date(2018, 0, 6),
        //     "value": 550,
        //     "value2": 354,
        //     "value3": 250
        // }, {
        //     "date": new Date(2018, 0, 7),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 8),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 9),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 10),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 11),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 12),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 13),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 14),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 15),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }, {
        //     "date": new Date(2018, 0, 16),
        //     "value": 420,
        //     "value2": 350,
        //     "value3": 600
        // }];

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.labels.template.fill = am4core.color("#fff");
        dateAxis.dateFormats.setKey("day", "dd/MM/yyyy");
        dateAxis.periodChangeDateFormats.setKey("day", "dd/MM/yyyy");



        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.disabled = true; //disables axis line
        // valueAxis.renderer.labels.template.disabled = true; //disables labels
        valueAxis.renderer.grid.template.disabled = true;  //disables grid
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");


        let series1 = this.createSeries("value", "Normal", chart);
        // series1.disabled = true;
        let series2 = this.createSeries("value1", "Abnormal Temperature", chart);

        let series4 = this.createSeries("value3", "Abnormal Questionnaire", chart);
        let series3 = this.createSeries("value2", "Access Fail", chart);



        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.contentAlign = "right";
        chart.legend.labels.template.fill = am4core.color("#fff");
        chart.legend.valueLabels.template.fill = am4core.color("#fff");
        chart.legend.labels.template.truncate = false;
        chart.legend.labels.template.wrap = true;

        // chart.scrollbarX = new am4core.Scrollbar();
        // chart.scrollbarX.minHeight = 4;
        // chart.scrollbarX.parent = chart.bottomAxesContainer;
        // this.customizeGrip(chart.scrollbarX.startGrip);
        // this.customizeGrip(chart.scrollbarX.endGrip);
        chart.cursor = new am4charts.XYCursor();

    }

    customizeGrip = (grip) => {
        // Remove default grip image
        grip.icon.disabled = true;

        // Disable background
        grip.background.disabled = true;

        // Add rotated rectangle as bi-di arrow
        var img = grip.createChild(am4core.Circle);
        img.width = 15;
        img.height = 15;
        img.fill = am4core.color("#999");
        img.align = "center";
        img.valign = "middle";

        // Add vertical bar
        var line = grip.createChild(am4core.Circle);
        line.height = 15;
        line.width = 15;
        line.fill = am4core.color("#999");
        line.valign = "middle";

    }


    createSeries = (field, name, chart) => {
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = field;
        series.dataFields.dateX = "date";
        series.name = name;
        series.tooltipText = "{dateX}: [b]{valueY}[/]";
        series.strokeWidth = 2;

        if (name === 'Normal') {
            series.fill = am4core.color("#12AF07");
            series.stroke = am4core.color("#12AF07");
        } else if (name === 'Abnormal Temperature') {
            series.fill = am4core.color("#d91e18");
            series.stroke = am4core.color("#d91e18");
        } else if (name === 'Abnormal Questionnaire') {
            series.fill = am4core.color("#d14d21");
            series.stroke = am4core.color("#d14d21");
        } else if (name === 'Access Fail') {
            series.fill = am4core.color("#d17f21");
            series.stroke = am4core.color("#d17f21");
        }

        var bullet = series.bullets.push(new am4charts.CircleBullet());
        // bullet.circle.stroke = am4core.color("#fff");
        bullet.circle.strokeWidth = 2;

        return series;
    }


    render() {
        return (
            <div id={`${this.props.chartid}`} style={{ width: "100%", height: "400px" }}></div>
        )
    }
}