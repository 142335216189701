import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import Login from './pages/Login';
import DashboardPage from './pages/DashboardPage';
import ForgotPassword from './pages/ForgotPasswprd';
import AdminDashboard from './pages/Rbac/AdminDashboard';
import ManageRolesList from './pages/Rbac/ManageRolesList';
import ManageRoles from './pages/Rbac/Manageroles';
import ManageUsersList from './pages/Rbac/ManageUsersList';
import ManageUsers from './pages/Rbac/ManageUsers';
import ManageOrganizationList from './pages/Rbac/ManageOrganizationList';
import ManageOrganization from './pages/Rbac/ManageOrganization';
import ManageBusinessList from './pages/Rbac/ManageBusinessList';
import ManageBusiness from './pages/Rbac/ManageBusiness';
import Logout from './pages/Logout';
import ChangePassword from './pages/ChangePassword';
import Verification from './pages/Verification';

import Location360 from "./pages/location360/location360";
import Employee360 from './pages/employee360/Employee360';
import Trend360 from './pages/trend360/Trend360';
import { localstorage } from './config/axiosConfig';

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/forgotpassword" component={ForgotPassword} />
    <Route exact path="/changepassword" component={ChangePassword} />
    <Route exact path="/verification" component={Verification} />
  </Switch>
)

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        localstorage() ? (
          <React.Fragment>
            <Header />
            <Component {...props} />
          </React.Fragment>
        )
          : (<Redirect to="/" />)
      }
    ></Route>
  );
};

const AppRoutes = () => {
  return (
    <Switch>
      <AuthenticatedRoute path="/location360" component={Location360} />
      <AuthenticatedRoute path="/employee360" component={Employee360} />
      <AuthenticatedRoute path="/trend360" component={Trend360} />
      <AuthenticatedRoute path="/dashboard" component={DashboardPage} />
      <AuthenticatedRoute path="/admindashboard" component={AdminDashboard} />
      <AuthenticatedRoute path="/managerolesList" component={ManageRolesList} />
      <AuthenticatedRoute path="/manageroles" component={ManageRoles} />
      <AuthenticatedRoute path="/manageroles/:id?" component={ManageRoles} />
      <AuthenticatedRoute path="/manageuserList" component={ManageUsersList} />
      <AuthenticatedRoute path="/manageusers" component={ManageUsers} />
      <AuthenticatedRoute path="/manageorganizationList" component={ManageOrganizationList} />
      <AuthenticatedRoute path="/manageorganization/:id?" component={ManageOrganization} />
      <AuthenticatedRoute path="/managebusinessList" component={ManageBusiness} />
      <AuthenticatedRoute path="/managebusiness" component={ManageBusinessList} />
      <UnauthenticatedRoutes />
    </Switch>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
