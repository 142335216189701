import React from 'react';
import { Link } from 'react-router-dom';
import FormMiddleware from './../../middlewares/FormMiddleware';
import {
  roleOrganization,
  userRoleList,
  saveUserRole,
  getParticularRole,
  updateUserRole,
} from '../../middlewares/RbacMiddleware';

class ManageRoles extends FormMiddleware {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      permissionList: [],
      rolename: '',
      organizationid: '',
      landingpage: '',
      status: '',
      createdby: '',
      editRole: {},
      roleId: '',
      updatedby: '',
    };
  }
  async componentDidMount() {
    const roleId = this.props.match.params.id;
    await this.getCompanyList();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.setState({
      createdby: userDetails.displayname,
      updatedby: userDetails.displayname,
    });
    if (!roleId) {
      this.getPermissionList();
      this.singleSelectBox('status');
    } else {
      this.setState({
        roleId,
      });
      this.getParticularRole(roleId);
    }
  }

  getParticularRole = async (roleId) => {
    try {
      const { data } = await getParticularRole(roleId);
      if (!data) return;
      const organizationid = document.querySelector(`#organizationid`);
      organizationid.value = data.organizationid;
      organizationid.disabled = true;
      const landingpage = document.querySelector(`#landingpage`);
      landingpage.value = data.landingpage;
      const status = document.querySelector(`#status`);
      if (data.status === 'A') {
        status.checked = true;
      }
      this.setState({
        rolename: data.rolename,
        organizationid: data.organizationid,
        landingpage: data.landingpage,
        status: data.status,
        createdby: data.createdby,
        editRole: data,
      });
      this.selectboxActive(data.role.type);
    } catch (error) {
      console.error(error);
    }
  };

  selectboxActive = (roles) => {
    this.setState({
      permissionList: roles,
    });
    roles.map((roleHead) => {
      let count = 0;
      roleHead.details.map((role) => {
        if (role.active === 'Y') {
          count += 1;
          const roleSelect = document.querySelectorAll(`#${role.id}`);
          roleSelect.forEach((element) => {
            element.checked = true;
          });
        }
      });
      if (count === roleHead.details.length) {
        const roleSelect = document.querySelectorAll(`#${roleHead.groupid}`);
        roleSelect.forEach((element) => {
          element.checked = true;
        });
      }
    });
  };

  singleSelectBox = (value) => {
    const getHtml = document.querySelector(`#${value}`);
    if (getHtml.checked) {
      getHtml.checked = true;
      this.setState({
        [value]: 'A',
      });
    } else {
      getHtml.checked = false;
      this.setState({
        [value]: 'I',
      });
    }
  };
  getCompanyList = async () => {
    try {
      const { data } = await roleOrganization();
      if (!data && !data.length) return;
      this.setState({
        companyList: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getPermissionList = async () => {
    try {
      const { data } = await userRoleList();
      if (!data && !data.length) return;
      this.setState({
        permissionList: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  selectAllGroup = (e, groupid) => {
    const { permissionList } = this.state;
    const getGroupCheckbox = e.target;
    let getValuePosition = null;
    let checkedArray = [];
    const newPermissionList = permissionList.filter(
      (value) => value.groupid !== groupid
    );
    const [getParticularGroup] = permissionList.filter((value, index) => {
      if (value.groupid === groupid) {
        getValuePosition = index;
        return value;
      }
    });
    getParticularGroup.details.map((value) => {
      const getCheckbox = document.querySelectorAll(`#${value.id}`);
      if (getGroupCheckbox.checked) {
        getGroupCheckbox.checked = true;
        getCheckbox.forEach((element) => {
          element.checked = true;
        });
        value.active = 'Y';
        checkedArray.push(value);
      } else {
        getGroupCheckbox.checked = false;
        getCheckbox.forEach((element) => {
          element.checked = false;
        });
        value.active = 'N';
        checkedArray.push(value);
      }
    });
    getParticularGroup.details = checkedArray;
    newPermissionList.splice(getValuePosition, 0, getParticularGroup);
    this.setState({
      permissionList: newPermissionList,
    });
  };

  getParticularCheckbox = (e, id, groupid) => {
    const { permissionList } = this.state;
    let getValuePosition = null;
    const newPermissionList = permissionList.filter(
      (value) => value.groupid !== groupid
    );
    const [getParticularGroup] = permissionList.filter((value, index) => {
      if (value.groupid === groupid) {
        getValuePosition = index;
        return value;
      }
    });

    const newPermission = getParticularGroup.details.filter(
      (value) => value.id !== id
    );
    let getPermissionPosition = null;
    const [getParticularPermission] = getParticularGroup.details.filter(
      (value, index) => {
        if (value.id === id) {
          getPermissionPosition = index;
          return value;
        }
      }
    );
    const getCheckbox = document.querySelectorAll(`#${id}`);
    getCheckbox.forEach((element) => {
      if (element.checked) {
        element.checked = true;
        getParticularPermission.active = 'Y';
      } else {
        element.checked = false;
        getParticularPermission.active = 'N';
      }
    });
    newPermission.splice(getPermissionPosition, 0, getParticularPermission);
    getParticularGroup.details = newPermission;
    newPermissionList.splice(getValuePosition, 0, getParticularGroup);
    const getGroupIdCheckbox = document.querySelector(`#${groupid}`);
    const newPermissionTrueLength = newPermission.filter(
      (value) => value.active === 'Y'
    ).length;
    if (newPermission.length === newPermissionTrueLength) {
      getGroupIdCheckbox.checked = true;
    } else {
      getGroupIdCheckbox.checked = false;
    }
    this.setState({
      permissionList: newPermissionList,
    });
  };
  selectAll = (e) => {
    const { permissionList } = this.state;
    permissionList.map((value, index) => {
      let checkedArray = [];
      const getGroupCheckbox = document.querySelector(`#${value.groupid}`);
      value.details.map((groupValue) => {
        const getCheckbox = document.querySelectorAll(`#${groupValue.id}`);
        getGroupCheckbox.checked = true;
        getCheckbox.forEach((element) => {
          element.checked = true;
        });
        groupValue.active = 'Y';
        checkedArray.push(groupValue);
      });
      permissionList.splice(index, 1);
      value.details = checkedArray;
      permissionList.splice(index, 0, value);
      this.setState({
        permissionList: permissionList,
      });
    });
  };

  resetAll = (e) => {
    const { permissionList } = this.state;
    permissionList.map((value, index) => {
      let checkedArray = [];
      const getGroupCheckbox = document.querySelector(`#${value.groupid}`);
      value.details.map((groupValue) => {
        const getCheckbox = document.querySelectorAll(`#${groupValue.id}`);
        getGroupCheckbox.checked = false;
        getCheckbox.forEach((element) => {
          element.checked = false;
        });
        groupValue.active = 'N';
        checkedArray.push(groupValue);
      });
      permissionList.splice(index, 1);
      value.details = checkedArray;
      permissionList.splice(index, 0, value);
      this.setState({
        permissionList: permissionList,
      });
    });
  };

  onSave = async () => {
    const {
      createdby,
      landingpage,
      organizationid,
      rolename,
      status,
      permissionList,
    } = this.state;
    if (!landingpage) return;
    if (!rolename) return;
    if (!organizationid) return;
    const json = {
      createdby,
      landingpage,
      organizationid,
      rolename,
      description: rolename,
      status,
      role: { type: permissionList },
    };
    try {
      const { data } = await saveUserRole(json);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  onUpdate = async () => {
    const {
      landingpage,
      organizationid,
      rolename,
      status,
      permissionList,
      editRole,
      roleId,
      createdby,
      updatedby,
    } = this.state;
    if (!landingpage) return;
    if (!rolename) return;
    if (!organizationid) return;
    const json = {
      createdby,
      createddate: editRole.createddate,
      id: roleId,
      landingpage,
      organizationid,
      rolename,
      description: rolename,
      status,
      role: { type: permissionList },
      updatedby,
    };
    try {
      const { data } = await updateUserRole(json, roleId);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  onDelete = async () => {
    const {
      landingpage,
      organizationid,
      rolename,
      permissionList,
      editRole,
      roleId,
      createdby,
      updatedby,
    } = this.state;
    if (!landingpage) return;
    if (!rolename) return;
    if (!organizationid) return;
    const json = {
      createdby,
      createddate: editRole.createddate,
      id: roleId,
      landingpage,
      organizationid,
      rolename,
      description: rolename,
      status: 'D',
      role: { type: permissionList },
      updatedby,
    };
    try {
      const { data } = await updateUserRole(json, roleId);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      companyList,
      permissionList,
      rolename,
      roleId,
      editRole,
    } = this.state;
    return (
      <React.Fragment>
        <div id="wrapper">
          <div id="content-wrapper">
            <div className="tp-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item" style={{ cursor: 'pointer' }}>
                  <img src={'images/fileicon.png'} />
                </li>
                <li className="breadcrumb-item activelink">
                  <Link to="/admindashboard">Admin</Link>
                </li>
                <li className="breadcrumb-item active">ADD NEW Role</li>
              </ol>
            </div>
            <div className="container-fluid mb-15" id="project-list"></div>
            <div className="white-box" style={{ overflow: 'hidden' }}>
              <div className="col-md-12">
                <form autoComplete="off">
                  <div className="row">
                    <div className="col-md-6 backtoorganisationlist">
                      <Link to="/managerolesList">
                        {' '}
                        <i className="fas fa-reply"></i>
                        <span>Back to List</span>
                      </Link>
                    </div>
                  </div>
                  <div className="admin_listpage">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Role Name<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Role Name"
                                name="rolename"
                                id="rolename"
                                value={rolename}
                                onChange={(e) => this.handleUserInput(e)}
                              />
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Company<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                name="organizationid"
                                id="organizationid"
                                onChange={(e) => this.handleUserInput(e)}
                              >
                                <option value="">Select Company</option>
                                {companyList &&
                                  companyList.length &&
                                  companyList.map((value, index) => {
                                    return (
                                      <option key={index} value={value.id}>
                                        {value.companyname}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Landing Page<sup>*</sup>
                            </label>
                            <div className="col-md-7">
                              <select
                                className="form-control"
                                name="landingpage"
                                id="landingpage"
                                onChange={(e) => this.handleUserInput(e)}
                              >
                                <option value="">Select Landing Page</option>
                                <option value="KYC">KYC</option>
                                <option value="OPEN4BIZSCORE">
                                  OPEN4BIZSCORE
                                </option>
                                <option value="ANALYSIS">ANALYSIS</option>
                                <option value="KEYDRIVERS">KEYDRIVERS</option>
                                <option value="CAMPAIGN">CAMPAIGN</option>
                                <option value="AIRECOMMENDATION">
                                  AIRECOMMENDATION
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="filter-lbl col-md-4">
                              Status
                            </label>
                            <div
                              className="col-md-7"
                              style={{ paddingTop: '15px' }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="status"
                                name="status"
                                onChange={(e) => this.singleSelectBox('status')}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="status"
                              ></label>
                              <span>Active</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-md-12 backtoorganisationlist">
                          <label className="filter-lbl admindetails_headings Location_font  col-md-4">
                            PERMISSIONS
                          </label>
                          <div className="clearfix"></div>
                          <div
                            className="pull-right"
                            style={{ paddingLeft: '20px' }}
                            onClick={(e) => {
                              !roleId ? this.resetAll(e) : this.resetAll(e); //this.selectboxActive(editRole.role.type);
                            }}
                          >
                            <span>Reset</span>
                          </div>
                          <div
                            className="pull-right"
                            onClick={(e) => this.selectAll(e)}
                          >
                            <span>Select All</span>
                          </div>
                        </div>
                      </div>
                      <div className="admin_listpage smborder_remove">
                        <div className="autoadjust_list">
                          {permissionList &&
                            permissionList.length &&
                            permissionList.map((value, index) => {
                              return (
                                <React.Fragment>
                                  <div className="box">
                                    <div className="heading_analysis">
                                      <label className="Customcheckbox">
                                        <input
                                          type="checkbox"
                                          name={value.groupid}
                                          id={value.groupid}
                                          value={value.groupid}
                                          onChange={(e) =>
                                            this.selectAllGroup(
                                              e,
                                              value.groupid
                                            )
                                          }
                                          //   checked={false}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                      {value.description}
                                    </div>
                                    <ul>
                                      {value.details &&
                                        value.details.length &&
                                        value.details.map(
                                          (detailsValue, detailsIndex) => {
                                            return (
                                              <React.Fragment>
                                                <li key={detailsIndex}>
                                                  <span className="custom-control admicontrol custom-switch">
                                                    <input
                                                      type="checkbox"
                                                      value={detailsValue.id}
                                                      name={detailsValue.id}
                                                      id={detailsValue.id}
                                                      onChange={(e) =>
                                                        this.getParticularCheckbox(
                                                          e,
                                                          detailsValue.id,
                                                          value.groupid
                                                        )
                                                      }
                                                    />
                                                    <label className="custom-control-label"></label>
                                                    <span>
                                                      {detailsValue.description}
                                                    </span>
                                                  </span>
                                                </li>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pull-right admin_actionsbtn">
                    <button type="button" className="btn btn-default">
                      Cancel
                    </button>
                    {!roleId ? (
                      <React.Fragment>
                        <button
                          type="button"
                          className="btn btn-default btn-save"
                          onClick={(e) => this.onSave()}
                        >
                          Save
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          type="button"
                          className="btn btn-default btn-save"
                          onClick={(e) => this.onUpdate()}
                        >
                          Update & Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-default btn-save"
                          onClick={(e) => this.onDelete()}
                        >
                          Delete
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ManageRoles;
